<template>
    <div class="page-content">
        <h3>
            <span>
                <img src="../../../public/dashboard-assets/images/M2/BackButton.png" />
            </span>
            Purchase Addon
        </h3>
        <div class="mt-4  table-responsive" style="min-height: 250px !important;">
            <table class="table text-center  bg-white">
                <thead style="background-color: #F1F0FF !important;">
                    <tr>
                        <th class="w-50">Item</th>
                        <th style="width: 20%;">Unit Price</th>
                        <th style="width: 10%;">No of Units</th>
                        <th style="width: 20%;">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in this.addons" :key="index">
                        <td>
                            {{ data.name }}
                            <h6 style="color: #888888;" class="mt-2">
                                Some description will come here{{ data.description }}
                            </h6>
                        </td>
                        <td>{{ CurrencyCode }} {{data.addonPrice}}</td>
                        <td>
                            <select class="form-select" v-model="selectedValues[index].Quantity"
                                @change="updateTotal(index, data.addonPrice , selectedValues[index].Quantity)">
                                <option v-for="number in [...Array(data.maxLimit + 1).keys()]" :key="number" :value="number">{{ number }}
                                </option>
                            </select>
                        </td>
                        <td>{{ CurrencyCode }} {{ data.addonPrice * selectedValues[index].Quantity }}</td>
                    </tr>

                    <tr style="background-color: #F1F0FF !important;">
                        <td colspan="3">
                            <span class="float-end">
                                Amount to be paid
                            </span>
                        </td>
                        <td>{{ CurrencyCode }} {{ this.totalAmount }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5">
            <span class="float-end">
                <button class="btn btn-primary" @click="PurchaseAddon()">Purchase</button>
            </span>
        </div>

        <div class="modal fade CreatePartnerModal_hp" id="EditBillingInfoModal" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" aria-labelledby="EditBillingInfoModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-centerm-auto" id="EditBillingInfoModalLabel">
                            <!-- UserActive -->
                            Billing Info
                        </h4>
                        <button type="button" class="btn-close" id="close" data-bs-dismiss="modal"
                            aria-label="btn-close" @click="ResetForm"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="row" id="CreateForm">
                            <div class="form-group mb-4">
                                <label class="input-hero" for="FirstName">
                                    <input class="input_area" v-model="this.PreferCompanyName" type="text" required=""
                                        @keydown="filterCompanyName" /><span class="input-name">Registered Company
                                        Name</span>
                                </label>
                            </div>
                            <!--   <option
                      v-for="item in GetLocationData.object.data"
                      :key="item"
                      :value="item.iso2"
                    >
                      {{ item.name }}
                    </option> -->
                            <div class="form-group mb-4">
                                <label class="input-hero" for="Category">
                                    <select class="form-select input_area select_ca" v-model="this.PreferEmail"
                                        @change="this.findConcernedPerson()">
                                        <option disabled selected>Select Preferred Email Address</option>
                                        <option v-for="users in allUsers" :key="users">
                                            {{ users.email }}
                                        </option>
                                    </select>
                                    <span class="input-name">Email Address</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="FirstName">
                                    <input class="input_area" v-model="this.PreferAttentionName" type="text" required=""
                                        disabled /><span class="input-name"> Concerned Person Name</span>
                                </label>
                            </div>
                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <div class="tag-input">
                                        <div v-for="(tag, index) in ccEmails" :key="index" class="tag"
                                            @click="removeTag(index)">
                                            {{ tag }}
                                            <span>x</span>
                                        </div>
                                        <input v-model="tagInput" @keydown.enter.prevent="addTag"
                                            placeholder="Add email">
                                    </div>
                                    <span class="input-name">CC Emails</span>
                                </label>
                                <span style="color: #7b68ee;"><i class="fa-solid fa-circle-info fa-sm "></i><span
                                        class="mx-2">Click Enter after adding CC Email</span></span>
                            </div>

                            <span class="mx-1 mb-3" style="font-weight: 600;">Address</span>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferStreet" type="text" required=""
                                        @keydown="filterSpecialChars" /><span class="input-name">Street</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferCity" type="text" required=""
                                        @keydown="filterAlphabetOnly" /><span class="input-name">City</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferState" type="text" required=""
                                        @keydown="filterAlphabetOnly" /><span class="input-name">State</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferCountry" type="text" required=""
                                        @keydown="filterAlphabetOnly" /><span class="input-name">Country</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferZip" type="text" required=""
                                        @keydown="filterNumbersOnly" /><span class="input-name">ZIP</span>
                                </label>
                            </div>

                            <div v-if="this.user.countryCode=='IN'">
                                <span class="mx-1 mb-3" style="font-weight: 600;">Additional Details</span>

                                <div class="form-group mb-4 mt-3">
                                    <label class="input-hero" for="LastName">
                                        <input class="input_area" v-model="this.PreferGstNo" type="text" required=""
                                            @input="validateGstNo" maxlength="15" @keydown="filterSpecialChars" /><span
                                            class="input-name">GST Number</span>
                                    </label>
                                    <div v-if="gstNoError" style="font-size: 14px; color: red;">{{ gstNoError }}</div>
                                </div>
                                <div class="form-group mb-4">
                                    <label class="input-hero" for="Category">
                                        <select class="form-select input_area select_ca" v-model="this.placeOfSupply">
                                            <option disabled selected>Select Preferred State</option>
                                            <option v-for="states in this.GetStateData.object.data" :key="states"
                                                :value="states.iso2">
                                                {{ states.name }}
                                            </option>
                                        </select>
                                        <span class="input-name">Place of Supply</span>
                                        <!-- <span class="text-danger">*</span> -->
                                    </label>
                                </div>
                            </div>
                            <div class="">
                                <button class="btn btn-primary btn-icon-text float-end"
                                    @click="UpdateBillingPreference()" data-bs-dismiss="modal"
                                    :disabled="!isGstNoValid && PreferGstNo !== ''">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// import { calculateRemainingDays } from '../../platformFeatures/dateUtils';
// import { getEncryptedData } from '../../platformFeatures/featureUtils';
import moment from "moment";

export default {
    name: "Details",
    components() {

    },
    data() {
        return {
            addons:[],
            billing: JSON.parse(localStorage.getItem('billingData')),
            PlanStatus: false,
            FreePlan: '',
            totalTokens: 0,
            usedTokens: 0,
            CurrencyCode: '',
            planName: '',
            price: 0,
            type: '',
            percentageData: 0,
            invoiceData: [],
            PreferCompanyName: '',
            PreferEmail: '',
            PreferGstNo: '',
            PreferAttentionName: '',
            PreferZip: '',
            PreferStreet: '',
            PreferCity: '',
            PreferState: '',
            PreferCountry: '',
            PreferenceId: 0,
            placeOfSupply:'',
            ccEmails: [],
            allUsers: [],
            invoiceShow: false,
            tagInput: '',
            uniqueEmails: new Set(),
            billingPreferenceData: [],
            GetStateData: {
                object: {
                    data: [],
                },
            },
            gstNoError: '',
            isGstNoValid: true,
            currentclientId:0,
            currentCompany: '',
            selectedValues: {},
            selectedFinalAmount: {},
            totalAmount: 0,
            finalAmount: 0
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        })
    },
    async created() {
        if (this.$route.query.id != undefined) {
            this.currentclientId = window.atob(this.$route.query.id);   
            this.currentCompany = window.atob(this.$route.query.name);
        }
        else {
            this.currentclientId = this.user.clientId;
        }
   
        this.addons = this.billing.object.data.plan.addons;
        this.addons.forEach((addon,index) => {
            this.selectedValues[index] = { Type: addon.addonType, AddonCode: addon.addonCode, Quantity:0 }; // Initialize with 0 or a default value
            this.selectedFinalAmount[index] = 0; // Initialize with 0 or a default value
        });
        var data = await this.GetBillingPagePlanDetails({
            // subscriptionId: this.billing.object.data.subscription.subScriptionId\
            clientId: this.currentclientId
        });
        this.CurrencyCode = this.fetchCurrency(this.user.countryCode);
        this.totalTokens = data.object.data.allocatedTokens;
        this.usedTokens = data.object.data.consumedTokens;
        this.planName = data.object.data.planName;
        this.price = data.object.data.price;
        this.type = data.object.data.type;
        
        this.percentageData = this.CalculatePercentage(this.usedTokens, this.totalTokens);
        
        // await this.GetBillingPageCardDetails(
        //     {
        //         clientId: this.currentclientId
        //     }
        // );
        
        this.getAllUserDetails();
        

    },
    methods: {
        ...mapMutations(["setLoading"]),
        ...mapActions([
            'GetBillingPagePlanDetails',
            'GetBillingPageCardDetails',
            'GetEnterpriseInvoiceData',
            'GetInvoiceData',
            'GetBillingPreference',
            'GetAllUsers',
            'putBillingPreferenceData',
            'DownloadInvoice',
            'ShowWarningMessage',
            'getstatelocation',
            'postPurchaseAddon'
        ]),
        dateConvert: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },

        updateTotal(index,amount,qty) {   
            this.selectedFinalAmount[index] = amount * qty;
            this.totalAmount = Object.values(this.selectedFinalAmount).reduce((total, value) => total + value, 0);
        },
        
        async ValidationModal() {
            window.$('#ValidationModal').modal('show');
        },

        async PurchaseAddon(){
            var addons = Object.values(this.selectedValues).filter(item => item.Quantity !== 0);
            
            const data = {
                subscriptionId: this.billing.object.data.subscription.subScriptionId,
                body:{
                    Addons: addons
                }
            };
            console.log(data);
            const response = await this.postPurchaseAddon(data);
            var url = await response.object.data.hostedpage.url;
            window.open(url, '_self');
        },
        
        findConcernedPerson() {
            const selectedUser = this.allUsers.find(user => user.email === this.PreferEmail);
            this.PreferAttentionName = selectedUser.name;
        },
        addTag() {
            const emails = this.tagInput
                .split(/[\s,]+/)
                .filter(email => email.trim() !== '');

            for (const email of emails) {
                if (this.validateEmail(email)) {
                    if (!this.uniqueEmails.has(email)) {
                        this.ccEmails.push(email);
                        this.uniqueEmails.add(email);
                    } 
                } 
            }

            this.tagInput = '';
        },
        removeTag(index) {
            const emailToRemove = this.ccEmails[index];
            this.ccEmails.splice(index, 1);
            this.uniqueEmails.delete(emailToRemove);
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
        },
        validateGstNo() {
            const regex = /^[a-zA-Z0-9]{15}$/;
            if (this.PreferGstNo.length === 15 && regex.test(this.PreferGstNo)) {
                this.gstNoError = '';
                this.isGstNoValid = true;
            } else {
                this.gstNoError = this.PreferGstNo ? 'GST Number must be exactly 15 characters long and contain no special characters.' : '';
                this.isGstNoValid = false;
            }
        },
        
        async downloadInvoice(id, invoiceNumber) {
            await this.DownloadInvoice({
                invoiceId: id,
                invoiceNumber: invoiceNumber
            })
        },
        checkDownloadStatus(status) {
            const lowerStatus = this.invoiceStatusLowerCase(status);
            return lowerStatus !== 'draft' && lowerStatus !== 'void';
        },
        async emptyEditModal() {
            this.PreferCompanyName = '';
            this.PreferEmail = '';
            this.PreferGstNo = '';
            this.PreferAttentionName = '';
            this.PreferZip = '';
            this.PreferStreet = '';
            this.PreferCity = '';
            this.PreferState = '';
            this.PreferCountry = '';
            this.ccEmails = [];
            this.uniqueEmails= new Set(),
            this.PreferenceId = '';
            this.placeOfSupply = '';
            
        },
        async openEditBillingInfoModal(data) {
            this.PreferenceId = data.preferenceId;
            this.PreferCompanyName = data.companyName;
            this.PreferEmail = data.email;
            this.PreferGstNo = data.gstNo;
            this.PreferAttentionName = data.attentionName;
            this.PreferZip = data.zip;
            this.PreferStreet = data.street;
            this.PreferCity = data.city;
            this.PreferState = data.state;
            this.PreferCountry = data.country;
            this.ccEmails = data.ccEmails;
            this.placeOfSupply= data.placeOfSupply;

            if(this.user.countryCode=="IN"){
                this.GetStateData = await this.getstatelocation({
                    countryCode:this.user.countryCode
                });
            }
            window.$("#EditBillingInfoModal").modal("show");
        },

        filterSpecialChars(event) {
            const key = event.key;
            const isValid = /^[a-zA-Z0-9 ]$/.test(key) || key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        filterAlphabetOnly(event) {
            const key = event.key;
            const isValid = /^[a-zA-Z]$/.test(key) ||
                key === 'Backspace' ||
                key === 'Delete' ||
                key === 'ArrowLeft' ||
                key === 'ArrowRight' ||
                key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        filterCompanyName(event) {
            const key = event.key;
            const isValid = /^[a-zA-Z0-9 &]$/.test(key) ||
                key === 'Backspace' ||
                key === 'Delete' ||
                key === 'ArrowLeft' ||
                key === 'ArrowRight' ||
                key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        filterNumbersOnly(event) {
            const key = event.key;
            const isValid = /^[0-9]$/.test(key) ||
                key === 'Backspace' ||
                key === 'Delete' ||
                key === 'ArrowLeft' ||
                key === 'ArrowRight' ||
                key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        invoiceStatusLowerCase(item){
            return item.toLowerCase();
        },
        async UpdateBillingPreference() {
            await this.putBillingPreferenceData({
                clientId:this.currentclientId,
                // clientId: 2084,
                attentionName: this.PreferAttentionName,
                clientName: "string",
                companyName: this.PreferCompanyName,
                gstNo: this.PreferGstNo,
                email: this.PreferEmail,
                street: this.PreferStreet,
                city: this.PreferCity,
                state: this.PreferState,
                country: this.PreferCountry,
                zip: this.PreferZip,
                placeOfSupply: this.placeOfSupply,
                toEmails: [
                    this.PreferEmail
                ],
                ccEmails: this.ccEmails,
                preferenceId: this.PreferenceId 

            });
            await this.emptyEditModal();
            window.$("#EditBillingInfoModal").modal("hide");
        },
        async ResetForm() {
            await this.emptyEditModal();
        },
        

        CalculatePercentage(used, total) {
            var percentage = (used / total) * 100;
            return percentage;
        },
        CheckStatus(dueDate) {
            let currentDate = new Date();
            let due_date = new Date(dueDate);
            let diff = due_date.getTime() - currentDate.getTime();
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
            this.Remainingdays = Math.abs(days);
            return this.Remainingdays;
        },
        fetchCurrency(data) {
            if (data == 'IN') {
                return '₹';
            } else {
                return '$';
            }
        },
        async CancelSubscription() {
            await this.putSubscriptionCancel({
                subscriptionId: this.billing.object.data.subscription.subScriptionId
            });
            await this.RefreshBillingDetails();
            location.reload();
        },

        async getAllUserDetails() {

            var model = {
                status: 4,
                clientId: this.currentclientId,
                limit: 999,
                page: 1,

            };

            var users = await this.GetAllUsers(model);
            this.allUsers = users.object.data.users;

        },
        async reactiveSubscription() {
            await this.postReactivateSubscription({
                subscriptionId: this.billing.object.data.subscription.subScriptionId
            });
            await this.RefreshBillingDetails();
            location.reload();
        },
    },
};
</script>

<style scoped>
.tag-input {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    width: 100%;

}

.tag {
    display: inline-block;
    background-color: #f0f0f0;
    padding: 2px 5px;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.tag span {
    cursor: pointer;
    margin-left: 5px;
}

.tag-input input {
    border: none;
    outline: none;
    width: 250px;
}

.demo-preview .badge {
    margin-right: 10px;
}

/* .tag-input {
  border: 1px solid #ccc;
  padding: 5px;
  min-height: 30px;
  width: 100%;
} */

.tag {
    display: inline-block;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.close {
    margin-left: 5px;
    cursor: pointer;
}

.badge {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 8px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #2d2d2d;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #f1f4f9;
}

.secondary {
    background-color: rgb(211, 211, 211);
    color: rgb(30, 30, 30);
}

.top-cards {
    border-radius: 15px;
    min-height: 200px;
    height: 100%;
}

.plan-cost-distribution-card {
    border-radius: 15px;
    min-height: 400px;

    /* height: 100%; */
}

.disable-text-selection {
    user-select: none;
}

.custom-progress {
    height: 20px;
    background-color: #E8E4FF;
    border-radius: 5px;
}

.custom-progress .progress-bar {
    background-color: #7B68EE;
}

.billing-info-card {
    border-radius: 15px;
    min-height: 250px;
}
</style>