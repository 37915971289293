<template>
    <div class="page-content">
        <li class="nav-item" v-if="getUserPermissionData({
            parent: 'Analytics Platform',
            child: 'Analytics_Platform', permission: 'Can_Access_Analytics_Platform', //update
        })">
            <a href="https://dev-data-automation.pmapstest.in/" target="_blank">
                Dev
            </a>
        </li>
        <li class="nav-item" v-if="getUserPermissionData({
            parent: 'Analytics Platform',
            child: 'Analytics_Platform', permission: 'Can_Access_Analytics_Platform', //update
        })">
            <a href="https://data-automation.pmapstest.in/" target="_blank">
                AP
            </a>
        </li>

        <iframe src="https://dev-data-automation.pmapstest.in/" title="Jaddu"
            style="width: 100%; height: 100%"></iframe>
    </div>
</template>
<script>
export default {

}
</script>