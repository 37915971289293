<template>
  <div class="page-content">
    <h3 class="mb-2 mb-md-0" style="display: flex; align-items: center;">
      <router-link :to="{
        name: 'ActiveTests',
        query: {
          id: this.routesearch ? encode(this.routesearch) : '',
          offset: this.routeoffset ? encode(this.routeoffset) : '',
          current: this.routecurrent ? encode(this.routecurrent) : '',
          filter: true,
        },
      }" class="p-0" type="button">
        <img src="../../../public/dashboard-assets/images/M2/BackButton.png" alt="Back Button"
          style="margin-right: 10px;" />
      </router-link>
      <div>
        <h4 class="mb-1">{{ this.editTestName }}</h4>
        <h6 class="text-muted">
          Test Id: {{ this.routetestId }}
        </h6>
      </div>
    </h3>
    <!-- Navigation Pill Start -->
    <div class="row mt-2">
      <div class="col-10">
        <ul class="nav nav-pills mb-3 partners_top_menu_section_hp mt-4 user_tabing_nav_section" id="pills-tab"
          role="tablist">
          <li class="nav-item my-1" role="presentation">
            <button class="nav-link btn px-3 mx-2" :class="this.overviewStatus ? 'active' : ''" id="overview-tab"
              data-bs-toggle="pill" data-bs-target="#overview" type="button" role="tab" aria-controls="overview"
              aria-selected="true" @click="changeTab('overview')">
              General
            </button>
          </li>
          <li class="nav-item my-1" role="presentation" v-if="superadmin() && this.showTab('General')">
            <button class="nav-link btn px-3" :class="this.generalStatus ? 'active' : ''" id="general-tab"
              data-bs-toggle="pill" data-bs-target="#general" type="button" role="tab" aria-controls="general"
              aria-selected="true" @click="changeTab('general')">
              General Settings
            </button>
          </li>
          <li class="nav-item my-1" role="presentation" v-if="superadmin() && this.showTab('Eva')">
            <button class="nav-link btn px-3" :class="this.evaStatus ? 'active' : ''" id="eva-tab" data-bs-toggle="pill"
              data-bs-target="#eva" type="button" role="tab" aria-controls="eva" aria-selected="false"
              @click="changeTab('eva')">
              eVA Settings
            </button>
          </li>
          <li class="nav-item my-1" role="presentation">
            <button class="nav-link btn px-3" :class="this.advanceStatus ? 'active' : ''" id="advance-tab"
              data-bs-toggle="pill" data-bs-target="#advance" type="button" role="tab" aria-controls="advance"
              aria-selected="false" @click="changeTab('advance')">
              Advance Settings
            </button>
          </li>
        </ul>
      </div>
      <div class="top_heading_CreatePartner_btn col-2 mb-3 mt-4">
        <!-- <router-link :to="{
          name: 'InviteCandidatePage',

  params: {
    id: encode(this.routetestId),
    clientId: encode(this.testSummary.clientId),
    testName: encode(this.editTestName),
  },
        }"> -->
        <router-link :to="{
          name: 'InviteCandidatePage',
          query: {
            id: encode(this.routetestId),
            clientId: encode(this.testSummary.clientId),
            testName: encode(this.editTestName),
            search: encode(this.routesearch),
            offset: encode(this.routeoffset),
            current: encode(this.routecurrent),
            page: encode('edit-instance')
          },
        }">
          <button type="button" class="btn btn-primary btn-icon-text pl-4 float-end mt-2">
            Send Invites
          </button>

        </router-link>
      </div>
    </div>
    <!-- Navigation Pill End -->

    <!-- Overview Start-->
    <div class="row mt-4" v-if="this.overviewStatus">
      <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-12">

        <div class="card-header card-header-Active-Invite-kj">
          <h5>TEST SUMMARY</h5>
        </div>
        <div class="card p-3">
          <div class="card-body p-0">
            <div class="active-list-invite-kj">
              <div class="d-flex justify-content-between align-items-center mb-4">
                <span>
                  <img src="../../../public/dashboard-assets/images/M2/time-icon.png" class="icon-img-kj-active"
                    alt="" />Test Time
                </span>
                <!-- <span>{{ this.testTime }}min</span> -->
                <span>{{ dateConvert(this.testSummary.tesTime) }}</span>
              </div>
              <!-- <div class="d-flex justify-content-between align-items-center mb-4 mt-4">
                <span><img src="../../../public/dashboard-assets/images/M2/user.png" class="icon-img-kj-active"
                    alt="" />Candidates
                </span>
                {{ this.testSummary.completed }}/{{
                  this.testSummary.scheduled
                }}
              </div> -->
              <div class="d-flex justify-content-between align-items-center mb-4">
                <span><img src="../../../public/dashboard-assets/images/M2/date-time-icon.png"
                    class="icon-img-kj-active" alt="" />Start Date
                </span>
                <span v-if="this.testSummary.startTime != null">{{
                  this.testSummary.startTimeNew
                  }}</span>
                <span v-else>-</span>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <span><img src="../../../public/dashboard-assets/images/M2/date-time-icon.png"
                    class="icon-img-kj-active" alt="" />End Date
                </span>
                <span v-if="this.testSummary.endTime != null">{{
                  this.testSummary.endTimeNew
                  }}</span>
                <span v-else>-</span>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <span><img src="../../../public/dashboard-assets/images/M2/date-time-icon.png"
                    class="icon-img-kj-active" alt="" />Cooling Period
                </span>
                <span class="cursor-pointer" @click="navigateToId('coolingPeriodAccordian')"
                  v-if="this.testSummary.coolingPeriodDays != null">
                  {{ this.testSummary.coolingPeriodDays }} Days
                </span>
                <span v-else>
                  <a class="cursor-pointer" @click="navigateToId('coolingPeriodAccordian')"> Add </a>
                </span>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-4">
                <span><img src="../../../public/dashboard-assets/images/M2/date-time-icon.png"
                    class="icon-img-kj-active" alt="" />Auto Exipry
                </span>
                <span class="cursor-pointer" @click="this.showAutoExpiryModal()"
                  v-if="this.testSummary.autoExpiry != null">
                  After {{ this.testSummary.autoExpiry }} Day(s)
                </span>
                <span v-else>
                  <a class="cursor-pointer" @click="this.showAutoExpiryModal()"> Add </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-8 col-md-8 col-sm-12">
        <div class="card mb-4 overflow-scroll" style="height: 330px;">
          <table class="table bg-white">
            <thead class="table-header-bg-active-kj sticky-top" style="z-index: 1;">
              <tr>
                <th class="font-15 text-dark">Test Sections</th>
                <th class="font-15 text-dark text-center">Questions</th>
                <th class="font-15 text-dark text-center">Benchmark(%)</th>
                <th class="font-15 text-dark text-center">Weightage</th>
                <th class="font-15 text-dark text-center">Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in this.testSection" :key="item">
                <td class="text-wrap"> {{ item.sectionName }}</td>
                <td class="text-center">{{ item.noOfQuestion }}</td>
                <td class="text-center">{{ item.passingScore }}</td>
                <td class="text-center" v-if="item.weightageValue != null"> {{ item.weightageValue }} </td>
                <td class="text-center" v-else>-</td>
                <td class="text-center" v-if="this.getHour(item) > 0">
                  {{ this.getHour(item) }} hr {{ this.getMins(item) }} min
                </td>
                <td class="text-center" v-else>
                  {{ this.getMins(item) }} min
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="accordion" id="accordionEditTestName">
        <div class="accordion-item">
          <h2 class="accordion-header" id="accordionHeaderEditTestName">
            <div class="accordion-button d-flex justify-content-between align-items-center">
              <div>
                <h4 style="color: #2D2D2D;">Edit Test</h4>
                <h5 style="color: #888888;" class="mt-2">
                  Type in the new name, and hit "Update" to make your changes! Easy peasy!
                </h5>
              </div>
            </div>
          </h2>

          <div class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionEditTestName">
            <div class="accordion-body">
              <div class="row mt-4">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group mb-4">
                    <label class="input-hero">
                      <input type="text" class="form-control" id="start-time" v-model="this.editTestName"
                        :maxlength="75" />
                      <span class="input-name">
                        Test Name
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 row test_instance_button mt-1">
                  <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1 me-6">
                    <button class="secondary-button1" type="button" @click="this.GeneralData()">
                      Reset
                    </button>
                  </div>
                  <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                    <button class="btn btn-primary" type="button" @click="this.UpdateTestName()">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion" id="accordionProctoring" v-if="EnterprisePlan || superadmin()">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <div class="accordion-button d-flex justify-content-between align-items-center">
              <div>
                <h4 class="text-dark">Anti Cheating Settings</h4>
                <h5 style="color: #888888;" class="mt-2">
                  Set up measures to help keep assessments fair and secure! Choose options like browser lockdown, webcam
                  monitoring, or randomizing questions to minimize cheating. Let’s ensure everyone plays by the rules!
                </h5>
              </div>
              <button @click="toggleCollapse('showProctorStatus')"
                :class="{ 'toggle-button': true, 'active': showProctorStatus }" class="ms-2"></button>
            </div>
          </h2>
          <div class="accordion-collapse collapse" :class="{ show: showProctorStatus }" aria-labelledby="headingOne"
            data-bs-parent="#accordionProctoring">
            <div class="accordion-body">
              <div class="container">
                <div class="row mt-2">
                  <div class="col">
                    <div class="d-flex flex-column">
                      <!-- <div class="me-4">
                        <div>Video Proctoring</div>
                        <button @click="toggleVideoProctor" 
                          :class="{ 'toggle-button': true, 'active': showProctorStatus }" class="mt-2"></button>
                      </div> -->
                      <div class="me-4 mb-4">
                        <span>Picture Capture</span>
                        <button @click="togglepictureCapture"
                          :class="{ 'toggle-button': true, 'active': showProctorStatus }" :readonly="true"
                          class="float-end"></button>
                        <!-- :class="{ 'toggle-button': true, 'active': pictureCaptureStatus }"  -->
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="d-flex flex-column">
                      <div class="mb-4">
                        <span>Logs Enabled</span>
                        <button @click="toggleLogsEnabled"
                          :class="{ 'toggle-button': true, 'active': showProctorStatus }" :readonly="true"
                          class="float-end"></button>
                        <!-- :class="{ 'toggle-button': true, 'active': logsEnabledStatus }" -->
                      </div>
                      <!-- <div class="me-4">
                        <div>Video Proctoring</div>
                        <button @click="toggleVideoProctor" 
                          :class="{ 'toggle-button': true, 'active': showProctorStatus }" class="mt-2"></button>
                      </div>  -->
                      <!-- <div class="me-4 mb-4">
                        <span>Audio Capture</span>
                        <button @click="toggleAudioCapture"
                          :class="{ 'toggle-button': true, 'active': showProctorStatus }" :readonly="true" class="float-end"></button>
                         :class="{ 'toggle-button': true, 'active': audioCaptureStatus }" 
                      </div>
                      <div class="me-4 mb-4">
                         <span>Video Capture</span>
                        <button @click="toggleVideoCapture"
                          :class="{ 'toggle-button': true, 'active': showProctorStatus }" class="float-end"></button> 
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="my-3 test_instance_button row">
                <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                  <button class="secondary-button1" type="button" @click="this.GetProctorSettingData()">
                    Reset
                  </button>
                </div>
                <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                  <button class="btn btn-primary" type="button" @click="this.PutProctorSettingsData()"> Update
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div v-if="EnterprisePlan || ScalePlan || GrowPlan || StartupPlan || superadmin()" class="accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="scheduleHeading">
            <div class="accordion-button d-flex justify-content-between align-items-center">
              <div>
                <h4 style="color: #2D2D2D;">Schedule Test</h4>
                <h5 style="color: #888888;" class="mt-2">
                  Ready to set a date? Just select the test, pick your preferred date and time, and click "Schedule" to
                  lock it in!
                </h5>
              </div>
              <button @click="toggleCollapse('TestDurationStatus')"
                :class="{ 'toggle-button': true, 'active': TestDurationStatus }" class="ms-2"></button>
            </div>
          </h2>
          <div :id="'scheduleHeading'" class="accordion-collapse collapse" :class="{ show: TestDurationStatus }"
            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row mt-4">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="form-group mb-4">
                    <label class="input-hero">
                      <input type="datetime-local" class="form-control" id="start-time" :min="this.minDateTime"
                        v-model="scheduledStartTime" :disabled="TestDurationStatus ? false : true" />
                      <span class="input-name">
                        Start Time
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div class="form-group mb-4">
                    <label class="input-hero">
                      <input type="datetime-local" class="form-control" id="end-time" :min="this.minDateTime"
                        v-model="scheduledEndTime" :disabled="TestDurationStatus ? false : true"
                        :close-on-select="ConvertedEndTime == null" />
                      <span class="input-name">
                        End Time
                      </span>
                    </label>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                  v-if="this.user.accountLocation != this.user.countryCode">
                  <div class="form-group mb-4">
                    <label class="input-hero">
                      <select class="form-select input_area select_ca" v-model="TimeZoneValue">
                        <!-- <option disabled selected>
                      Select Preferred Time Zone
                    </option> -->
                        <option v-for="item in GetTimeZoneData.object.data" :key="item" :value="item.gmtOffsetName">
                          {{ item.zoneName }} {{ item.gmtOffsetName }}
                        </option>
                      </select>
                      <span class="input-name">
                        Timezone
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="my-3 test_instance_button row">
                <!-- <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                  <button class="secondary-button1" type="button" @click="this.resetData()"
                    :disabled="TestDurationStatus == false">
                    Reset
                  </button>
                </div> -->
                <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                  <button class="btn btn-primary" type="button" :disabled="!this.scheduleTestShow"
                    @click="this.UpdateTestDurationData()"> Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="EnterprisePlan || ScalePlan || GrowPlan || StartupPlan || superadmin()" class="accordion"
        id="coolingPeriodAccordian">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <div class="accordion-button d-flex justify-content-between align-items-center">
              <div>
                <h4 class="text-dark">Cooling Period Settings</h4>
                <h5 style="color: #888888;" class="mt-2">
                  Set a cooldown period between test attempts. Just specify the duration to give candidates time before
                  they can retake the test. This helps maintain assessment integrity!
                </h5>
              </div>
            </div>
          </h2>
          <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="container">
                <div class="row mt-2">
                  <div class="d-flex flex-row">
                    <div class="col-12 col-sm-6 col-md-4 col-xl-4 me-3">
                      <div class="form-group mb-4">
                        <label class="input-hero">
                          <select class="form-select input_area select_ca" id="durationOption"
                            v-model="this.selectedDuration">
                            <option v-for="item in this.newCoolingDuration" :key="item.id" :value="item.days">
                              {{ item.name }}
                            </option>
                            <option value='-1' selected>+ Custom Days</option>
                          </select>
                          <span class="input-name">
                            Select Cooling Period Duration
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="me-4 w-50" v-if="selectedDuration == -1">
                      <label class="input-hero">
                        <label class="form-outline">
                          <input type="number" id="durationOption" class="input_area select_ca" v-model="newDurationDay"
                            placeholder="Add Custom Days" :disabled="selectedDuration != -1" />
                          <span class="input-name">
                            Add Cooling Period Duration
                          </span>
                        </label>
                      </label>
                    </div>
                    <div class="me-4 w-50">
                      <label class="input-hero">
                        <Multiselect v-model="selectedOptions" placeholder="Choose Mode" mode="tags" track-by="value"
                          label="name" :close-on-select="false" :options="coolingPeriodOptions" :searchable="true"
                          :group-select="false" required>
                        </Multiselect>
                        <span class="input-name">
                          Select Cooling Period Mode
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-3 test_instance_button row">
                <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1 me-3"
                  v-if="this.testSummary.coolingPeriodDays != null">
                  <button class="secondary-button1" type="button" @click="this.UpdateCoolingPeriodData(0)">
                    Remove
                  </button>
                </div>
                <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                  <button class="btn btn-primary" type="button" @click="this.UpdateCoolingPeriodData(1)">
                    <span v-if="this.testSummary.coolingPeriodDays != null">Update </span> <span v-else>Set</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="EnterprisePlan || ScalePlan || GrowPlan || StartupPlan || superadmin()" class="accordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="accordionHeaderEditTestName">
            <div class="accordion-button d-flex justify-content-between align-items-center">
              <div>
                <h4 class="text-dark mb-2">Disclaimer</h4>
                <h5 style="color: #888888;" class="">
                  Please note that the information and results provided are for assessment purposes only. We do not
                  guarantee accuracy or completeness, and results may vary based on individual performance and
                  circumstances. Use at your discretion.
                </h5>
              </div>
            </div>
          </h2>
          <div class="accordion-collapse collapse show" aria-labelledby="headingOne">
            <div class="accordion-body">
              <div class="row">
                <div class="form-group mb-2 QuillEditor-kj">
                  <QuillEditor theme="snow" v-model:content="disclaimer" contentType="html" :toolbar="toolbarOptions" />
                </div>
              </div>
            </div>
          </div>
          <div class="test_instance_button row mb-4 mx-1">
            <!-- <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
              <button class="secondary-button1" type="button" @click="this.GetProctorSettingData()">
                Reset
              </button>
            </div> -->
            <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
              <button class="btn btn-primary" type="button" @click="this.UpdateTestDisclaimer()">
                <span v-if="this.disclaimer == '' || this.disclaimer == null"> Set </span>
                <span v-else> Update </span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Overview End -->

    <!-- General Settings Start -->
    <div v-if="superadmin() && this.generalStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Anti Cheating Settings</h4>
              <h5 style="color: #888888;" class="mt-2">
                Set up measures to help keep assessments fair and secure! Choose options like browser lockdown, webcam
                monitoring, or randomizing questions to minimize cheating. Let’s ensure everyone plays by the rules!
              </h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-6 mt-2">
                <div class="col">
                  <div class="d-flex flex-wrap">
                    <div class="me-4">
                      <div>Web Proctoring</div>
                      <button @click="toggleWebProctor" :class="{ 'toggle-button': true, 'active': webProctorStatus }"
                        class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Video Proctoring</div>
                      <button @click="toggleVideoProctor" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': videoProctorStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Picture Capture</div>
                      <button @click="togglepictureCapture" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': pictureCaptureStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Video Capture</div>
                      <button @click="toggleVideoCapture" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': videoCaptureStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Audio Capture</div>
                      <button @click="toggleAudioCapture" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': audioCaptureStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Logs Enabled</div>
                      <button @click="toggleLogsEnabled" :disabled="!webProctorStatus"
                        :class="{ 'toggle-button': true, 'active': logsEnabledStatus }" class="mt-2"></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="my-3 test_instance_button row">
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.GetProctorSettingData()">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" @click="this.PutProctorSettingsData()"> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- General Settings End -->

    <!-- eVA Settings Start -->

    <div v-if="EnterprisePlan || superadmin() && this.evaStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center">
            <div>
              <h4 style="color: #2D2D2D;">VNA Language Scoring Weightage</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2" v-if="this.showEvaWeightages">
                <div class="col">
                  <div class="table-responsive mb-4">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Section Name</th>
                          <th v-for="parameter in parameters" :key="parameter.parameterName">{{ parameter.parameterName
                            }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="section in sectionNames.sort(({ sectionId: a }, { sectionId: b }) => b - a)"
                          :key="section.sectionId">
                          <td>{{ section.sectionName }}</td>
                          <td v-for="parameter in parameters" :key="parameter.parameterName"
                            :class="{ 'highlight-red': (highlightError('eva',parameter.parameterName, section.sectionName)) }">
                            <span
                              :class="{ 'cursor-pointer': getWeightage(section.sectionName, parameter.parameterName) != 'NA' }"
                              @click="editVnaWeightage(section.sectionName, parameter.parameterName)">{{
                              getWeightage(section.sectionName, parameter.parameterName) }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr>
                    <div class="mt-5 text-center">
                      You can revert to the default weightages by clicking
                      <button class="btn btn-primary" type="button" @click="GenerateDefaultLanguageWeightage">
                        Generate default eVA™ weightages
                      </button>
                      button
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" v-else>
                <h5 class="mt-3 mb-3"> Let's start by generating default weightages by clicking
                  <button class="btn btn-primary" type="button" @click="GenerateDefaultLanguageWeightage">
                    Generate default eVA™ weightages
                  </button>
                  button
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="EnterprisePlan || superadmin() && this.evaStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center">
            <div>
              <h4 style="color: #2D2D2D;">Language Preferences</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div>
                  <!-- <h4 style="font-weight: 600;">Text Preferences</h4> -->
                  <div class="row">
                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-3"
                      v-for="(item, index) in textPreferences" :key="item.preferenceName">
                      <div class="form-group mb-4">
                        <label class="input-hero">
                          <input type="text" class="form-control" v-model="item.preferenceValue" :maxlength="75"
                            @input="markChanged(index)" />
                          <span class="input-name">{{ item.preferenceLabel }}</span>
                        </label>
                      </div>
                      <div class="d-flex justify-content-end mt-2">
                        <button class="btn btn-primary" @click="updatePreference(item)"
                          v-if="changedPreferences[index]">
                          Update
                        </button>
                      </div>
                    </div>

                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-3" v-for="item in boolPreferences"
                      :key="item.preferenceName">
                      <div class="d-flex flex-wrap">
                        <div class="me-4">
                          <div>{{ item.preferenceLabel }}</div>
                          <button @click="togglePreference(item)" class="toggle-button"
                            :class="{ 'mt-2': true, 'toggle-button': true, 'active': item.preferenceValue == 'TRUE' ? true : false }">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-3" v-for="(item, index) in dropPreferences"
                    :key="item.preferenceName">
                    <div class="d-flex flex-wrap">
                      <div class="me-4">
                        <div>{{ item.preferenceLabel }}</div>
                        <div class="select-container mt-2">
                          <select v-model="item.preferenceValue" class="custom-select" @change="dropChanged(index)">
                            <option value="en-US">English (US)</option>
                            <option value="en-IN">English (India)</option>
                            <option value="en-GB">English (GB)</option>
                            <option value="hi-IN">Hindi (India)</option>
                            <option value="es-ES">Spanish (ES)</option>
                            <option value="ja-JP">Japan (JP)</option>
                            <option value="en-PH">English (Philippines)</option>

                          </select>
                          <span class="select-icon">&#9662;</span>
                        </div>
                        <div class="d-flex justify-content-end mt-2">
                          <button class="btn btn-primary" @click="updatePreference(item)"
                            v-if="changedDropdownPreferences[index]">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="EnterprisePlan || superadmin() && this.evaStatus" class="accordion mx-2">
      <div class="accordion-item">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center">
            <div>
              <h4 style="color: #2D2D2D;">Language Preferences</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row">
                <div class="col" v-if="this.showLanguageAPIValue == true">
                  <div class="table-responsive mb-4">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="width: 20%;">Api Name</th>
                          <!-- <th>Is Default Language</th> -->
                          <th style="width: 20%">Language Name</th>
                          <th style="width: 20%">Question Type Name</th>
                          <th style="width: 25%">Section Name</th>
                          <th style="width: 15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="me-4">
                              <Multiselect v-model="selectedLAPICAPIName" placeholder="API Type" mode="single"
                                track-by="value" label="name" :close-on-select="true" :options="lAPICAPINameOptions"
                                :searchable="true" :group-select="false" required>
                              </Multiselect>
                            </div>
                          </td>
                          <!-- <td>
                            <div class="me-4">
                              <button :class="{ 'toggle-button': true, 'active': preferenceValue }" class="mt-2">
                              </button>
                            </div>
                          </td> -->
                          <td>
                            <div class="me-4">
                              <div class="select-container mt-2">
                                <select class="custom-select" v-model="selectedLAPICLanguageName">
                                  <option
                                    v-if="selectedLAPICAPIName == 'google' || selectedLAPICAPIName == 'azure' || selectedLAPICAPIName == 'gpt'"
                                    value="en-US">English (US)</option>
                                  <option
                                    v-if="selectedLAPICAPIName == 'google' || selectedLAPICAPIName == 'azure' || selectedLAPICAPIName == 'gpt'"
                                    value="en-IN">English (India)</option>
                                  <option
                                    v-if="selectedLAPICAPIName == 'google' || selectedLAPICAPIName == 'azure' || selectedLAPICAPIName == 'gpt'"
                                    value="en-GB">English (GB)</option>
                                  <option
                                    v-if="selectedLAPICAPIName == 'google' || selectedLAPICAPIName == 'azure' || selectedLAPICAPIName == 'gpt'"
                                    value="hi-IN">Hindi (India)</option>
                                  <option
                                    v-if="selectedLAPICAPIName == 'google' || selectedLAPICAPIName == 'azure' || selectedLAPICAPIName == 'gpt'"
                                    value="es-ES">Spanish (ES)</option>
                                  <option
                                    v-if="selectedLAPICAPIName == 'google' || selectedLAPICAPIName == 'azure' || selectedLAPICAPIName == 'gpt'"
                                    value="ja-JP">Japan (JP)</option>
                                  <option v-if="APICAPNAME(selectedLAPICAPIName)" value="en-PH">English (Philippines)
                                  </option>

                                  <option v-if="selectedLAPICAPIName == 'context'" value="fill_blank">Fill in the Blank
                                  </option>
                                  <option v-if="selectedLAPICAPIName == 'context'" value="story">Story</option>
                                  <option v-if="selectedLAPICAPIName == 'context'" value="relevance">Relevance</option>
                                  <option v-if="selectedLAPICAPIName == 'context'" value="customer">Customer</option>
                                  <option v-if="selectedLAPICAPIName == 'context'" value="solution">Solution</option>

                                  <option v-if="selectedLAPICAPIName == 'ettiquete'" value="chat">Chat</option>
                                  <option v-if="selectedLAPICAPIName == 'ettiquete'" value="email">Email</option>

                                  <option v-if="selectedLAPICAPIName == 'sentence'" value="formation">Formation</option>

                                  <option v-if="selectedLAPICAPIName == 'interview'" value="en-US">English (US)</option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                          </td>
                          <td
                            v-if="selectedLAPICAPIName == 'google' || selectedLAPICAPIName == 'azure' || selectedLAPICAPIName == 'gpt'">
                            <Multiselect v-model="selectedLAPICQuestTypeId" placeholder="Question Type" mode="single"
                              track-by="value" label="name" :close-on-select="true" :options="lAPICQuestTypeOptions"
                              :searchable="true" :group-select="false" required>
                            </Multiselect>
                          </td>
                          <td v-else>
                            -
                          </td>
                          <td>
                            <div class="me-4"
                              v-if="selectedLAPICAPIName != 'google' && selectedLAPICAPIName != 'azure' && selectedLAPICAPIName != 'gpt'">
                              <div class="select-container mt-2">
                                <select class="custom-select" v-model="selectedLAPICSecId">
                                  <option v-for="item in this.testSection" :key="item" :value="item.secId"> {{
                                    item.sectionName }}</option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                            <div v-else>
                              -
                            </div>
                          </td>
                          <td>
                            <span @click="PostLAPICDetails()" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:2% ; border-radius: 15px;">
                              <span class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i>
                            </span>
                          </td>
                        </tr>
                        <tr v-for="item in this.lAPICModel" :key="item.languageApiConditionId">
                          <td v-if="!item.editLAPICMode">
                            <Multiselect v-model="item.apiName" disabled placeholder="API Type" mode="single"
                              track-by="value" label="name" :close-on-select="true" :options="lAPICAPINameOptions"
                              :searchable="true" :group-select="false">
                            </Multiselect>
                          </td>
                          <td v-else>
                            <Multiselect v-model="item.apiName" placeholder="API Type" mode="single" track-by="value"
                              label="name" :close-on-select="true" :options="lAPICAPINameOptions" :searchable="true"
                              :group-select="false">
                            </Multiselect>
                          </td>
                          <td v-if="!item.editLAPICMode">
                            <div class="me-4">
                              <div class="select-container mt-2">
                                <select class="custom-select" disabled>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt') && item.languageName == 'en-US'"
                                    value="en-US">English (US)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt') && item.languageName == 'en-IN'"
                                    value="en-IN">English (India)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt') && item.languageName == 'en-GB'"
                                    value="en-GB">English (GB)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt') && item.languageName == 'hi-IN'"
                                    value="hi-IN">Hindi (India)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt') && item.languageName == 'es-ES'"
                                    value="es-ES">Spanish (ES)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt') && item.languageName == 'ja-JP'"
                                    value="ja-JP">Japan (JP)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt') && item.languageName == 'en-PH'"
                                    value="en-PH">English (Philippines) </option>

                                  <option v-if="item.apiName == 'context' && item.languageName == 'fill_blank'"
                                    value="fill_blank">Fill in the Blank</option>
                                  <option v-if="item.apiName == 'context' && item.languageName == 'story'"
                                    value="story">Story</option>
                                  <option v-if="item.apiName == 'context' && item.languageName == 'relevance'"
                                    value="relevance">Relevance</option>
                                  <option v-if="item.apiName == 'context' && item.languageName == 'customer'"
                                    value="customer">Customer</option>
                                  <option v-if="item.apiName == 'context' && item.languageName == 'solution'"
                                    value="solution">Solution</option>

                                  <option v-if="item.apiName == 'ettiquete' && item.languageName == 'chat'"
                                    value="chat">Chat</option>
                                  <option v-if="item.apiName == 'ettiquete' && item.languageName == 'email'"
                                    value="email">Email</option>

                                  <option v-if="item.apiName == 'sentence' && item.languageName == 'formation'"
                                    value="formation">Formation</option>

                                  <option v-if="item.apiName == 'interview' && item.languageName == 'en-US'"
                                    value="en-US">English (US)</option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                          </td>
                          <td v-else>
                            <div class="me-4">
                              <div class="select-container mt-2">
                                <select class="custom-select" v-model="item.languageName">
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt')"
                                    value="en-US">English (US)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt')"
                                    value="en-IN">English (India)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt')"
                                    value="en-GB">English (GB)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt')"
                                    value="hi-IN">Hindi (India)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt')"
                                    value="es-ES">Spanish (ES)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt')"
                                    value="ja-JP">Japan (JP)</option>
                                  <option
                                    v-if="(item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt')"
                                    value="en-PH">English (Philippines) </option>

                                  <option v-if="item.apiName == 'context'" value="fill_blank">Fill in the Blank</option>
                                  <option v-if="item.apiName == 'context'" value="story">Story</option>
                                  <option v-if="item.apiName == 'context'" value="relevance">Relevance</option>
                                  <option v-if="item.apiName == 'context'" value="customer">Customer</option>
                                  <option v-if="item.apiName == 'context'" value="solution">Solution</option>

                                  <option v-if="item.apiName == 'ettiquete'" value="chat">Chat</option>
                                  <option v-if="item.apiName == 'ettiquete'" value="email">Email</option>

                                  <option v-if="item.apiName == 'sentence'" value="formation">Formation</option>

                                  <option v-if="item.apiName == 'interview'" value="en-US">English (US)</option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                          </td>
                          <td v-if="!item.editLAPICMode">
                            <div v-if="item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt'">
                              <Multiselect v-model="item.questionTypeId" disabled placeholder="API Type" mode="single"
                                track-by="value" label="name" :close-on-select="true" :options="lAPICQuestTypeOptions"
                                :searchable="true" :group-select="false">
                              </Multiselect>
                            </div>
                            <div v-else>
                              -
                            </div>
                          </td>
                          <td v-else>
                            <div v-if="item.apiName == 'google' || item.apiName == 'azure' || item.apiName == 'gpt'">
                              <Multiselect v-model="item.questionTypeId" placeholder="API Type" mode="single"
                                track-by="value" label="name" :close-on-select="true" :options="lAPICQuestTypeOptions"
                                :searchable="true" :group-select="false">
                              </Multiselect>
                            </div>
                            <div v-else>
                              -
                            </div>
                          </td>
                          <td v-if="!item.editLAPICMode">
                            <div class="me-4"
                              v-if="item.apiName != 'google' && item.apiName != 'azure' && item.apiName != 'gpt'">
                              <div class="select-container mt-2">
                                <select class="custom-select" disabled>
                                  <option v-for="section in this.testSection" :key="section" v:bind:value="item.secId">
                                    {{ item.sectionName }}
                                  </option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                            <div v-else>
                              -
                            </div>
                          </td>
                          <td v-else>
                            <div class="me-4"
                              v-if="item.apiName != 'google' && item.apiName != 'azure' && item.apiName != 'gpt'">
                              <div class="select-container mt-2">
                                <select class="custom-select" v-model="item.secId">
                                  <option v-for="section in this.testSection" :key="section" :value="section.secId">
                                    {{ section.sectionName }}
                                  </option>
                                </select>
                                <span class="select-icon">&#9662;</span>
                              </div>
                            </div>
                            <div v-else>
                              -
                            </div>
                          </td>
                          <td v-if="!item.editLAPICMode">
                            <span @click="editLAPICItem(item)" class="btn btn-primary"
                              style=" font-weight: 600; border: 2px solid #7b68ee; margin:2% ; border-radius: 15px;">
                              <span class="mx-2">Edit</span><i class="fa-regular fa-pen-to-square fa-lg"></i>
                            </span>
                            <span @click="deleteLAPICItem(item)" class="btn btn-primary" style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; 
                              border: 2px solid #7b68ee; margin:2% ; border-radius: 15px;">
                              <span class="mx-2">Delete</span><i class="fa-solid fa-ban fa-lg"></i>
                            </span>
                          </td>
                          <td v-else>
                            <span @click="saveLAPICItem(item)" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:2% ; border-radius: 15px;">
                              <span class="mx-2">Save</span><i class="fa-regular fa-pen-to-square fa-lg"></i>
                            </span>
                            <span @click="cancelLAPICItem(item)" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:2% ; border-radius: 15px;">
                              <span class="mx-2">Cancel</span><i class="fa-solid fa-ban fa-lg"></i>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-else>
                  <h5 class="mt-3 mb-3"> Let's start by generating default values by clicking
                    <button class="btn btn-primary" type="button" @click="GenerateDefaultLanguageValues">
                      Generate default settings
                    </button>
                    button
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- eVA Settings End -->

    <!-- Advance Settings Start -->

    <div v-if="EnterprisePlan || superadmin() && this.advanceStatus" class="accordion mx-2">
      <div class="accordion-item">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center">
            <div>
              <h4 class="text-dark">Test Preference</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div class="col">
                  <div class="d-flex flex-wrap">
                    <div class="me-4">
                      <div>Behavioural Interview Question</div>
                      <button @click="toggleIsBehaviouralInterviewQuestionEnabled()"
                        :class="{ 'toggle-button': true, 'active': IsBehaviouralInterviewQuestionEnabledStatus }"
                        class="mt-2">
                      </button>
                    </div>
                    <div class="me-4">
                      <div>Managerial Reccomendation</div>
                      <button @click="toggleIsManagerialReccomendationEnabled()"
                        :class="{ 'toggle-button': true, 'active': IsManagerialReccomendationEnabledStatus }"
                        class="mt-2"></button>
                    </div>
                    <div class="me-4">
                      <div>Manual Evaluation</div>
                      <button @click="toggleIsEvaluationEnabled()"
                        :class="{ 'toggle-button': true, 'active': IsEvaluationEnabledStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4">
                      <div>Show Answersheet</div>
                      <button @click="toggleIsShowAnswerSheet()"
                        :class="{ 'toggle-button': true, 'active': IsShowAnswerSheetStatus }" class="mt-2"></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- 
            <div class="my-3 test_instance_button row" >
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.GetAdvanceSettings()">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" @click="this.putProctorSettings()"> Update
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div v-if="superadmin() && this.advanceStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center"
            style="border-radius: 16px 16px 16px 16px !important;">
            <div>
              <h4 style="color: #2D2D2D;">Report Setting</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row align-items-center mb-4 mt-2">
                <div class="col">
                  <div class="d-flex flex-wrap">
                    <div class="me-4 mx-2">
                      <div>Apti Shorten</div>
                      <button @click="toggleAptiShortenStatus()"
                        :class="{ 'toggle-button': true, 'active': aptiShortenStatus }" class="mt-2">
                      </button>
                    </div>
                    <div class="me-4 mx-2">
                      <div>Apti Detailed</div>
                      <button @click="toggleAptiDetailedStatus()"
                        :class="{ 'toggle-button': true, 'active': aptiDetailedStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4 mx-2">
                      <div>Psy Shorten</div>
                      <button @click="togglePsyShortenStatus()"
                        :class="{ 'toggle-button': true, 'active': psyShortenStatus }" class="mt-2"></button>
                    </div>

                    <div class="me-4 mx-2">
                      <div>Psy Detailed</div>
                      <button @click="togglePsyDetailedStatus()"
                        :class="{ 'toggle-button': true, 'active': psyDetailedStatus }" class="mt-2"></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="my-3 mt-5  test_instance_button row">
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="secondary-button1" type="button" @click="this.GetReportSettingsData()">
                  Reset
                </button>
              </div>
              <div class="col-6 col-sm-12 col-md-2 col-xl-1 col-lg-1">
                <button class="btn btn-primary" type="button" @click="this.PutReportSettingsDetails()"> Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div v-if="EnterprisePlan || superadmin() && this.advanceStatus" class="accordion mx-2">
      <div class="accordion-item">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center">
            <div>
              <h4 style="color: #2D2D2D;">Candidate Additional Details</h4>
              <h5 style="color: #888888;" class="mt-2">
                We ask for the candidate's name, email, phone number, and region by default.
                Additionally, request information on skills, previous experiences, and any specific notes to aid in the
                evaluation process.
                These fields will be visible to the candidate when filling out the registration form.
              </h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="row" v-if="this.showAdditionalField == true">
                <div class="col-12">
                  <!-- Mayuresh -->
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead style="background-color: #F1F0FF !important;">
                        <tr>
                          <th style="max-width: 150px; word-wrap: break-word; white-space: normal;">Field Name</th>
                          <th>Field Type</th>
                          <th>Mandatory</th>
                          <th style="max-width: 300px; word-wrap: break-word; white-space: normal;">Options</th>
                          <th style="min-width: 240px; word-wrap: break-word; white-space: normal;">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><input v-model="postFieldName"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td>
                            <div class="select-container" style="height: 100%; min-height: 40px;">
                              <select v-model="postFieldType" class="custom-select">
                                <option value="DateofBirth">Date of Birth</option>
                                <option value="Number">Number</option>
                                <option value="Text">Text</option>
                                <option value="Date">Date</option>
                                <option value="File">File</option>
                                <option value="Dropdown">Dropdown</option>
                                <option value="PAN">PAN</option>
                                <option value="Aadhaar">Aadhaar</option>
                              </select>
                              <span class="select-icon">&#9662;</span>
                            </div>
                          </td>
                          <td>
                            <button @click="postToggleCollapse"
                              :class="{ 'toggle-button': true, 'active': postMandatoryStatus }" class="ms-2"></button>
                          </td>
                          <td v-if="postFieldType == 'Dropdown'">
                            <input v-model="postOptions"
                              style="width:100%; max-width: 400px; border-radius: 5px; padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td v-else>
                          </td>
                          <td>
                            <span @click="PostCandidateAdditionalField()" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i></span>
                            <span @click="ResetCandidateAdditionalField()" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Reset</span><i class="fa-solid fa-ban fa-lg"></i> </span>

                          </td>
                        </tr>
                        <tr v-for="item in AdvanceCandidateDetails" :key="item.cadmid">
                          <td style="max-width: 150px; word-wrap: break-word; white-space: normal;">
                            <span v-if="!item.editMode">{{ item.fieldName }}</span>
                            <input v-else v-model="item.fieldName"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td>
                            <span v-if="!item.editMode">{{ item.fieldType }}</span>
                            <div v-else class="select-container">
                              <select v-model="item.fieldType" class="custom-select">
                                <option value="DateofBirth">Date of Birth</option>
                                <option value="Number">Number</option>
                                <option value="Text">Text</option>
                                <option value="Date">Date</option>
                                <option value="File">File</option>
                                <option value="Dropdown">Dropdown</option>
                                <option value="PAN">PAN</option>
                                <option value="Aadhaar">Aadhaar</option>
                              </select>
                              <span class="select-icon">&#9662;</span>
                            </div>
                          </td>
                          <td>
                            <button @click="toggleIsMandatory(item)"
                              :class="{ 'toggle-button': true, 'active': item.isMandatory }"
                              class="mandatorybutton ms-2" :disabled="!item.editMode">
                              <!-- {{ item.isMandatory ? 'True' : 'False' }} -->
                            </button>
                          </td>
                          <td v-if="item.fieldType == 'Dropdown'" style="">
                            <div style="width: 200px; max-height: 100px; display:inline-flex;" v-if="!item.editMode">
                              <Multiselect mode="single" :close-on-select="true" :options="item.options.split(',')"
                                placeholder="View options">
                              </Multiselect>
                            </div>
                            <!-- <span v-if="!item.editMode" style="display: inline-block; max-height: 100px; overflow: scroll;">
                              {{ item.options }}
                            </span> -->
                            <input v-else v-model="item.options"
                              style="width:100%; max-width: 400px; border-radius: 5px; padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td v-else>

                          </td>
                          <td style="max-width: 240px; word-wrap: break-word; white-space: normal;">
                            <span v-if="!item.editMode" @click="editItem(item)" class="btn btn-primary"
                              style=" font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Edit</span><i class="fa-regular fa-pen-to-square fa-lg"></i></span>
                            <span v-else @click="saveItem(item)" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i></span>
                            <span v-if="item.editMode" @click="cancelEdit(item)" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Cancel</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                            <span v-else @click="deleteItem(item)" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Delete</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                            <!-- <button v-if="!item.editMode" @click="editItem(item)">Trust</button>
          <button v-else @click="saveItem(item)">Save</button>
          <button v-if="item.editMode" @click="cancelEdit(item)">Cancel</button> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <div class="text-center" v-else>
                <h5 class="mt-3 mb-3"> Add registration page fields by clicking on this
                  <button class="btn btn-primary" type="button" @click="showAdditionalFieldPanel"> Add Additional Fields
                  </button>
                  button
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="EnterprisePlan || superadmin() && this.advanceStatus" class="accordion mx-2" id="accordionExample">
      <div class="accordion-item" style="border-radius: 16px !important;">
        <h2 class="accordion-header" id="scheduleHeading">
          <div class="accordion-button d-flex justify-content-between align-items-center">
            <div>
              <h4 style="color: #2D2D2D;">Test Section Weightage</h4>
              <h5 style="color: #888888;" class="mt-2">Edit test name as per your requirements</h5>
            </div>
          </div>
        </h2>
        <div :id="'scheduleHeading'" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="container">
              <div class="d-flex align-items-center justify-content-center">
                <div class="text-center mx-2">
                  <p>Section Weightage Activation Date:</p>
                </div>
                <div class="mx-2">
                  <input style="width: 100%; border-radius: 5px; padding-left: 5%; height: 100%; min-height: 40px;" type="datetime-local"  v-model="this.secWeiActivationDate" />
                </div>
                <div class="mx-2">
                  <button @click="TestWeightageActivationDate()" class="btn btn-primary " 
                  style="font-weight: 600; border: 2px solid #7b68ee ; border-radius: 15px;"> Save</button>
                </div>
              </div>
              <hr>
              <div class="row" v-if="this.showSectionWeigtage == true">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table text-center">
                      <thead style="background-color: #F1F0FF !important;">
                        <tr>
                          <th style="max-width: 150px; word-wrap: break-word; white-space: normal;">Section</th>
                          <th>Minimum Score</th>
                          <th>Maximum Score</th>
                          <th style="max-width: 300px; word-wrap: break-word; white-space: normal;">Weightage Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="w-100" v-if="this.secWeiSectionList.length != 0">
                          <td style="width: 300px;">
                            <!-- <Multiselect placeholder="Choose Section" mode="single" :close-on-select="true"
                :options="this.testSection" track-by="secId" label="sectionName" :searchable="true" required>
              </Multiselect> -->
                            <div class="select-container mt-2 w-20">
                              <select v-model="postSectionName" class="custom-select">
                                <option v-for="item in this.secWeiSectionList" :key="item" :value="item.secId">
                                  {{ item.sectionName }}</option>
                              </select>
                              <span class="select-icon">&#9662;</span>
                            </div>
                          </td>
                          <td class="w-20">
                            <input type="number" class="form-control" v-model="this.postMinScore" :min="-100"
                              :max="100" />
                          </td>
                          <td class="w-20">
                            <input type="number" class="form-control" v-model="this.postMaxScore" :min="-100"
                              :max="100" />
                          </td>
                          <td class="w-20">
                            <input type="number" class="form-control" v-if="this.postSectionName === null" disabled
                              value="1" />
                            <input type="number" class="form-control" v-else v-model="this.postWeightageValue"
                              :min="-100" :max="100" />
                          </td>
                          <td class="w-20">
                            <span @click="PostTestSectionWeightage()" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i></span>
                            <span @click="ResetTestSectionWeightage()" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;">
                              <span class="mx-2">Reset</span>
                            </span>
                          </td>
                        </tr>
                        <tr v-for="item in TestSectionsDetails.sort(({sectionId:a}, {sectionId:b}) => b-a)"
                          :key="item.testId">
                          <td style="max-width: 150px; word-wrap: break-word; white-space: normal;">
                            <span>{{ item.sectionName }}</span>
                          </td>
                          <td>
                            <span v-if="!item.editTestSectionMode">{{ item.minScore }}</span>
                            <input v-else v-model="item.minScore"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td>
                            <span v-if="!item.editTestSectionMode">{{ item.maxScore }}</span>
                            <input v-else v-model="item.maxScore"
                              style="width:100%; border-radius: 5px;padding-left: 5%; height: 100%; min-height: 40px;" />
                          </td>
                          <td :class="{ 'highlight-red': (highlightError('sectionWeightage','', '')) }">
                            <span v-if="!item.editTestSectionMode">{{ item.weightageValue }}</span>
                            <input v-else v-model="item.weightageValue"
                              style="width:100%; max-width: 400px; border-radius: 5px; padding-left: 5%; height: 100%; min-height: 40px;" />
                            <!-- <span v-if="item.sectionName === ''">{{ totalWeightageValue }}</span> -->
                          </td>
                          <td class="w-20">
                            <span v-if="!item.editTestSectionMode" @click="editTestSectionItem(item)"
                              class="btn btn-primary"
                              style=" font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Edit</span><i class="fa-regular fa-pen-to-square fa-lg"></i></span>
                            <span v-else @click="saveTestSectionItem(item)" class="btn btn-primary"
                              style="font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Save</span><i class="fa-regular fa-circle-check fa-lg"></i></span>
                            <span v-if="item.editTestSectionMode" @click="cancelTestSectionEdit(item)"
                              class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Cancel</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                            <span v-else @click="deleteTestSectionItem(item)" class="btn btn-primary"
                              style="background-color: #F1F0FF; margin-left: 5%; color:#7b68ee; font-weight: 600; border: 2px solid #7b68ee; margin:5% ; border-radius: 15px;"><span
                                class="mx-2">Delete</span><i class="fa-solid fa-ban fa-lg"></i> </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="text-center" v-else>
                <h5 class="mt-3 mb-3"> Add section weightages by clicking on this
                  <button class="btn btn-primary" type="button" @click="showSectionWeigtagePanel"> Add Section
                    Weightage
                  </button>
                  button
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" modal fade" id="AddSectionWeightageModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm ">
        <div class="modal-content border-radius-kj-20 ">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto">
              <span class="font-size-20-kj"> New Section Weightage</span>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group mb-4">
              <label class="input-hero"> Section Name</label>
              <!-- <Multiselect placeholder="Choose Section" mode="single" :close-on-select="true"
                :options="this.testSection" track-by="secId" label="sectionName" :searchable="true" required>
              </Multiselect> -->
              <div class="select-container mt-2">
                <select class="custom-select">
                  <option v-for="item in this.testSection" :key="item"> {{ item.sectionName }}</option>
                </select>
                <span class="select-icon">&#9662;</span>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="input-hero"> Weightage Value </label>
              <input type="number" class="form-control" v-model="this.autoExpiryDay" :min="0" :max="100" />
            </div>
            <div class="form-group mb-4">
              <label class="input-hero"> Minimum Passing Score </label>
              <input type="number" class="form-control" v-model="this.autoExpiryDay" :min="1" :max="30"
                @input="blockDecimal" :step="1" />
            </div>
            <div class="form-group mb-4">
              <label class="input-hero"> Maximum Passing Score </label>
              <input type="number" class="form-control" v-model="this.autoExpiryDay" :min="1" :max="30"
                @input="blockDecimal" :step="1" />
            </div>
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="SetAutoExpirySettings">
                Save Details
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class=" modal fade" id="SetAutoExpiry" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm ">
        <div class="modal-content border-radius-kj-20 ">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto">
              <span class="font-size-20-kj"> Auto Expire Test</span>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group my-4 ">
              <div class="form-group mb-4">
                <label class="input-hero">
                  <input type="number" class="form-control" v-model="this.autoExpiryDay" :min="1" :max="30"
                    @input="blockDecimal" :step="1" />
                  <span class="input-name">
                    Days after which the attempt should auto expire
                  </span>
                </label>
              </div>
            </div>
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="SetAutoExpirySettings">
                Set Expiry
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" modal fade" id="SetVnaWeightageParameter" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm ">
        <div class="modal-content border-radius-kj-20 ">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto">
              <span class="font-size-20-kj"> Set {{ this.editVnaParamName }} value for {{ this.editVnaSectionName
                }}</span>
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group my-4 ">
              <div class="form-group mb-4">
                <label class="input-hero">
                  <input type="number" class="form-control" v-model="this.editVnaWeightageValue" :min="0" :max="1" />
                  <span class="input-name">
                    Enter {{ this.editVnaParamName }} value
                  </span>
                </label>
              </div>
            </div>
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="saveVnaWeightageItem">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Advance Settings End -->
    <div class="modal fade" id="DurationStatus" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
      data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-body">
            Are you sure you want to deactivate the scheduler?
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal">
                No
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="patchDuration">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="ProctorStatus" tabindex="-1" aria-labelledby="DeactivateProctorModalLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-body">
            This action will deactivate anti-cheating settings for this test. Proceed?
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="PutProctorSettingsData('0')">
                Deactive
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="AdvanceValidation" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-body">
            {{ this.advanceModalTitle }}
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal"
                @click="this.ResetPutAdvanceSettings()">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="this.PutAdvanceSettings()">
                {{ this.advanceModalStatus ? "Active" : " Deactive" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="PreferenceValidation" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-body">
            {{ this.preferenceModalTitle }}
            <div class="text-end test_instance_button mt-4">
              <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal"
                @click="this.ResetTogglePreference()">
                Cancel
              </button>
              <button type="button" class="btn btn-primary btn-download" @click="this.updatePreferenceData()">
                {{ this.preferenceModalStatus ? "Active" : " Deactive" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import { superadmin } from "../../utils/helper";
import Multiselect from "@vueform/multiselect";
import { QuillEditor } from "@vueup/vue-quill";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "ActiveTestEditInstance",
  components: {
    Multiselect,
    QuillEditor
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),

    totalWeightageValue() {
      return this.TestSectionsDetails.reduce((sum, item) => {
        return sum + (Number(item.weightageValue) || 0);
      }, 0);
    },
    sectionNames() {
      return this.model;
    },
    parameters() {
      const paramSet = new Set();
      this.sectionNames.forEach(section => {
        section.languageParameters.forEach(param => {
          paramSet.add(param.parameterName);
        });
      });
      return Array.from(paramSet).map(paramName => ({ parameterName: paramName }));
    },

    minDateTime() {
      return moment().format('YYYY-MM-DDTHH:mm');
    },
  },

  data() {
    return {
      TestDurationStatus: false,
      scheduledStartTime: '',
      scheduledEndTime: '',
      editTestName: '',
      scheduleTestShow: true,
      generalStatus: false,
      overviewStatus: true,
      TestTimeStatus: false,
      evaStatus: false,
      advanceStatus: false,
      showTestNameButtons: false,
      testSummary: [],
      testSection: [],
      routetestId: window.atob(this.$route.query.id),
      routesearch: window.atob(this.$route.query.search),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      webProctorStatus: false,
      showProctorStatus: false,
      videoProctorStatus: false,
      pictureCaptureStatus: false,
      videoCaptureStatus: false,
      audioCaptureStatus: false,
      logsEnabledStatus: false,
      IsBehaviouralInterviewQuestionEnabledStatus: false,
      IsEvaluationEnabledStatus: false,
      IsManagerialReccomendationEnabledStatus: false,
      IsShowAnswerSheetStatus: false,
      advanceModalTitle: '',
      advanceModalStatus: false,
      advanceType: '',
      GetTimeZoneData: {
        object: {
          data: [],
        },
      },
      TimeZoneValue: "",
      AdvanceCandidateDetails: [],
      editMode: false,
      editTestSectionMode: false,
      editLAPICMode: false,

      postFieldName: '',
      postFieldType: '',
      postMandatoryStatus: false,
      postOptions: '',
      aptiShortenStatus: false,
      aptiDetailedStatus: false,
      psyShortenStatus: false,
      psyDetailedStatus: false,
      TestSectionsDetails: [],
      model: [],
      textPreferences: [],
      boolPreferences: [],
      dropPreferences: [],
      defaultTextPreferences: [],
      changedPreferences: [],
      defaultDropdownPreferences: [],
      changedDropdownPreferences: [],
      preferenceModalTitle: '',
      preferenceModalStatus: false,
      currentPreferenceValue: [],
      newCoolingDuration: [],
      selectedDuration: 0,
      newDurationDay: "",
      selectedOptions: [],
      coolingPeriodOptions: [{ name: "Email Address", value: "EmailAddress" }, { name: "PAN", value: "PAN" }, { name: "Aadhaar", value: "Aadhaar" }, { name: "Mobile Number", value: "MobileNumber" }],
      autoExpiryDay: 1,
      disclaimer: '',
      showSectionWeigtage: false,
      postSectionName: '',
      postMinScore: 0,
      postMaxScore: 0,
      postWeightageValue: 0,
      showAdditionalField: false,
      showEvaWeightages: false,
      editVnaSectionName: '',
      editVnaParamName: '',
      editVnaWeightageValue: 0,
      lAPICAPINameOptions: [
        { name: "Google", value: "google" }, { name: "Azure", value: "azure" }, { name: "GPT", value: "gpt" }, { name: "Meta", value: "meta" }, { name: "Context", value: "context" },
        { name: "Sales", value: "sales" }, { name: "Ettiquete", value: "ettiquete" }, { name: "Interview", value: "interview" }, { name: "Sentence", value: "sentence" }],
      lAPICQuestTypeOptions: [{ name: "VnA Text", value: "1036" }, { name: "VnA Audio", value: "1037" }, { name: "VnA Pronunciation", value: "1038" }, { name: "Vna Long Audio", value: "1039" }],
      selectedLAPICAPIName: 'google',
      selectedLAPICLanguageName: '',
      selectedLAPICSecId: 0,
      selectedLAPICQuestTypeId: 0,
      showLanguageAPIValue: false,
      lAPICModel: [],
      billing: JSON.parse(localStorage.getItem('billingData')),
      ScalePlan: false,
      GrowPlan: false,
      StartupPlan: false,
      EnterprisePlan: false,
      secWeiSectionList: [],
      secWeiActivationDate: "",
      // additionalDetailsOptions: [
      //   { name: "DateofBirth", value: "DateofBirth" },
      //   { name: "Number", value: "Number" },
      //   { name: "Text", value: "Text" },
      //   { name: "Date", value: "Date" },
      //   { name: "File", value: "File" },
      //   { name: "Dropdown", value: "Dropdown" },
      //   { name: "PAN", value: "PAN" },
      //   { name: "Aadhaar", value: "Aadhaar" },
      // ]
      // sectionNames:[]
    }
  },
  watch: {
    scheduledStartTime() {
      this.validateEndMax();
    },
    scheduledEndTime() {
      this.validateEndMax();
    },
  },
  async created() {
    if (this.billing != null) {
      var data = this.billing.object.data.plan.planName;
      if (data == 'Scale') {
        this.ScalePlan = true;
      }
      else if (data == 'Grow') {
        this.GrowPlan = true;
      }
      else if (data == 'Startup') {
        this.StartupPlan = true;
      }
      else if (this.user.isEnterprise == true) {
        this.EnterprisePlan = true;
      }
    }
    await this.OverallData();
    await this.GetProctorSettingData();
    await this.initCoolingPeriodDetails();
    await this.GetTestDurationData();
    await this.GetDisclaimer();
  },
  methods: {
    ...mapActions([
      "activetestdetail",
      "activetestsection",
      "ShowWarningMessage",
      "putTestDuration",
      "patchTestDuration",
      "getTestDuration",
      "putTestName",
      "getProctorSettings",
      "putProctorSettings",
      "getAdvanceSettings",
      "putAdvanceSettings",
      "getTimeZone",
      "getCandidateAdditionalDetails",
      "getReportSettingsDetails",
      "getTestSectionsDetails",
      "getLanguagePreferencesDetails",
      "getLanguageWeightageDetails",
      "postDefaultLanguageWeightageDetails",
      "putLanguageWeightageDetails",
      "getLanguageApiConditionDetails",
      "putCandidateAdditionalField",
      "deleteCandidateAdditionalField",
      "postCandidateAdditionalField",
      "putReportSettingsDetails",
      "putTestSectionsDetails",
      "deleteTestWeightageValue",
      "putLanguagePreferencesDetails",
      "GetCoolingPeriodSetting",
      "GetCoolingPeriodDurationNew",
      "CoolingPeriodDurationAddUpdate",
      "CoolingPeriodSettingsAddUpdate",
      "PostAutoExipryTestSetting",
      "GetTestDisclaimer",
      "PatchTestDisclaimer",
      "postGenerateDefaultLanguageValues",
      "postLanguageApiConditionDetails",
      "DeleteLanguageApiConditionDetails",
      "postTestWeightageActivationDate"
    ]),
    ...mapMutations(["setLoading"]),
    superadmin,
    dateConvert: function (value) {
      return moment(value, "HH:mm:ss").format((moment(value, "HH:mm:ss").hour() === 0 ? "m [min]" : "h [hr] m [min]"));
    },
    async getTimeZones() {
      this.GetTimeZoneData = await this.getTimeZone({
        countryCode: this.user.accountLocation
      });
    },

    async postToggleCollapse() {
      this.postMandatoryStatus = !this.postMandatoryStatus;
    },

    async TestWeightageActivationDate(){
      await this.postTestWeightageActivationDate({ testId: this.routetestId, activationDate : this.secWeiActivationDate });
    },

    async GetDisclaimer() {
      var data = await this.GetTestDisclaimer({ testId: this.routetestId });
      if (data.status) {
        this.disclaimer = data.object.data.disclaimer;
        var p = document.querySelector(".ql-editor");
        p.innerHTML = this.disclaimer;
      }
    },

    convertToObjectArray(commaSeparatedString) {
      return commaSeparatedString
        .split(',')
        .map(item => item.trim()) // Remove leading/trailing whitespace
        .filter(item => item !== '') // Filter out empty strings
        .map(item => ({ name: item }));
      //.map(item => ({ name: item, $isDisabled: true }));
    },

    async PostTestSectionWeightage() {
      if (this.postSectionName == "" && this.secWeiSectionList.length != 0) {
        this.ShowWarningMessage("Section cannot be empty");
      }
      else if (this.postMinScore === "") {
        this.ShowWarningMessage("Minimum score cannot be empty");
      }
      else if (this.postMaxScore === "") {
        this.ShowWarningMessage("Maximum score cannot be empty");
      }
      else if(this.postMinScore > this.postMaxScore){
        this.ShowWarningMessage("Min score cannot be greater than Max Score");
      }
      else if (this.postWeightageValue === "") {
        this.ShowWarningMessage("Weightage Value cannot be empty");
      }
      else if(this.postSectionName === null && this.postWeightageValue > 1 && this.postWeightageValue < 1){
        this.ShowWarningMessage("Weightage value should be 1");
      }
      else {
        var isPsySec = false;
        if (this.postSectionName != null && this.postSectionName != 1010) {
          var element = this.testSection.find(item => item.secId == this.postSectionName);
          isPsySec = element.isPsySec;
        }

        var response = await this.putTestSectionsDetails({
          testId: this.routetestId,
          sectionId: this.postSectionName != "" ? this.postSectionName : null,
          minScore: this.postMinScore,
          maxScore: this.postMaxScore,
          weightageValue: this.postSectionName === null ? 1 : this.postWeightageValue,
          isPsy: isPsySec,
        });

        if (response.status) {
          this.secWeiSectionList = this.secWeiSectionList.filter(i=>i.secId != this.postSectionName);
          this.ResetTestSectionWeightage();
          await this.GetTestSectionsDetailsData();
        }
      }

    },

    async PostCandidateAdditionalField() {

      if (this.postFieldName == "") {
        this.ShowWarningMessage("Field Name cannot be empty");
      }
      else if (this.postFieldType == "") {
        this.ShowWarningMessage("Field Type cannot be empty");
      }
      else if (this.postFieldType == "Dropdown" && this.postOptions == "") {
        this.ShowWarningMessage("Options cannot be empty");
      }
      else {
        var object =
          [
            {
              "fieldName": this.postFieldName,
              "fieldType": this.postFieldType,
              "options": this.postOptions,
              "isMandatory": this.postMandatoryStatus,
              "srNo": 0,
              "fieldCategory": 1
            }
          ]

        var data = await this.postCandidateAdditionalField({
          testId: this.routetestId,
          createModel: object
        });

        if (data.status) {
          await this.GetCandidateDetailsData();
          this.postFieldName = '';
          this.postFieldType = '';
          this.postMandatoryStatus = false;
          this.postOptions = '';
        }
      }
    },

    ResetTestSectionWeightage() {
      this.postSectionName = '';
      this.postMinScore = 0;
      this.postMaxScore = 0;
      this.postWeightageValue = 0;
    },

    APICAPNAME(value) {
      if (value == 'google' || value == 'azure' || value == 'gpt') {
        return true;
      }
      return false;
    },
    async ResetCandidateAdditionalField() {
      this.postFieldName = '';
      this.postFieldType = '';
      this.postMandatoryStatus = false;
      this.postOptions = '';
    },

    toggleIsMandatory(item) {
      item.isMandatory = !item.isMandatory;
    },
    editItem(item) {
      item.editMode = true;
      item.originalData = { ...item };
    },
    editTestSectionItem(item) {
      item.editTestSectionMode = true;
      item.originalTestSectionData = { ...item };
    },
    editLAPICItem(item) {
      item.editLAPICMode = true;
      item.originalLAPICData = { ...item };
    },
    getHour(item) {
      var hour = item.sectionTime.split(":", 2);
      return hour[0];
    },
    getMins(item) {
      var mins = item.sectionTime.split(":", 2);
      return mins[1];
    },

    editVnaWeightage(sectionName, paramName) {
      this.editVnaSectionName = sectionName;
      this.editVnaParamName = paramName;

      var value = this.getWeightage(sectionName, paramName);
      if (value != 'NA') {
        this.editVnaWeightageValue = value;
        window.$("#SetVnaWeightageParameter").modal("show");
      }
    },

    async saveVnaWeightageItem() {
      const section = this.sectionNames.find(s => s.sectionName === this.editVnaSectionName);
      if (!section) return '';
      const param = section.languageParameters.find(p => p.parameterName === this.editVnaParamName);

      var response = await this.putLanguageWeightageDetails({
        languageWeightageConditionId: param.languageWeightageConditionId,
        testId: this.routetestId, //this.routetestId,
        questionTypeId: 0,
        sectionId: section.sectionId,
        weightageValue: this.editVnaWeightageValue,
        parameterId: param.parameterId,
      });
      if (response.status) {
        window.$("#SetVnaWeightageParameter").modal("hide");
        await this.GetLanguageWeightageData()
      }

    },

    async saveTestSectionItem(item) {

      if(item.minScore === "" || item.maxScore === "" || item.weightageValue === "")
      {
        this.ShowWarningMessage("One or more fields are empty");
      }
      else if(item.minScore > item.maxScore){
        this.ShowWarningMessage("Min score cannot be greater than Max score");
      }
      else if(item.sectionName === "Overall Test" && (item.weightageValue > 1 || item.weightageValue < 1)){
        this.ShowWarningMessage("Weightage value should be 1");
      }
      else{
        var response = await this.putTestSectionsDetails({
          testId: this.routetestId, //this.routetestId,
          sectionId: item.sectionId != "" ? item.sectionId : null,
          minScore: item.minScore,
          maxScore: item.maxScore,
          weightageValue: item.weightageValue,
          isPsy: false,
        });

        if (response.status) {
          await this.GetTestSectionsDetailsData();
        }
        item.editTestSectionMode = false;
      }
    },

    async saveLAPICItem(item) {
      item.editLAPICMode = true;
      var sectionId = null; var questionTypeId = 0; var IsDefaultLanguage = null;

      if (item.apiName == "google" || item.apiName == 'azure' || item.apiName == 'gpt') {
        questionTypeId = item.questionTypeId;
        IsDefaultLanguage = true;
        sectionId = null;
      }
      else {
        questionTypeId = 0;
        IsDefaultLanguage = null;
        sectionId = item.secId;
      }

      var response = await this.postLanguageApiConditionDetails({
        languageApiConditionId: item.languageApiConditionId,
        testId: this.routetestId,
        questionTypeId: questionTypeId,
        sectionId: sectionId,
        apiName: item.apiName,
        languageName: item.languageName,
        IsDefaultLanguage: IsDefaultLanguage
      });

      if (response.status) {
        await this.GetLanguageApiConditionData();
      }
    },

    async saveItem(item) {
      item.editMode = false;

      await this.putCandidateAdditionalField({
        testId: this.routetestId,
        masterId: item.cadmid,
        fieldName: item.fieldName.trim(),
        fieldType: item.fieldType,
        options: item.options,
        isMandatory: item.isMandatory,
        fieldCategory: 1
      });
    },
    cancelEdit(item) {
      Object.assign(item, item.originalData);
      item.editMode = false;
    },
    cancelTestSectionEdit(item) {
      Object.assign(item, item.originalTestSectionData);
      item.editTestSectionMode = false;
    },
    cancelLAPICItem(item) {
      Object.assign(item, item.originalLAPICData);
      item.editLAPICMode = false;
    },
    async deleteItem(item) {
      var data = this.AdvanceCandidateDetails.filter(i => i.cadmid === item.cadmid);
      var apiData = await this.deleteCandidateAdditionalField({
        Id: this.routetestId,
        masterId: data[0].cadmid
      });
      if (apiData.status) {
        this.GetCandidateDetailsData();
      }
    },
    async deleteTestSectionItem(item) {
      if(item.sectionId == ""){
        item.sectionId = -1;
      }
      var apiData = await this.deleteTestWeightageValue({
        testId: this.routetestId,  //this.routetestId,
        sectionId: item.sectionId
      });
      if (apiData.status) {
        this.FillSectionList();
        // this.AdvanceCandidateDetails = this.AdvanceCandidateDetails.filter(i => i.sectionId !== item.sectionId);
        await this.GetTestSectionsDetailsData();
      }
    },

    async deleteLAPICItem(item) {
      var apiData = await this.DeleteLanguageApiConditionDetails({
        Id: item.languageApiConditionId,
      });
      if (apiData.status) {
        await this.GetLanguageApiConditionData();
      }
    },

    async GetProctorSettingData() {
      var data = await this.getProctorSettings({
        testId: this.routetestId
      });
      var proctorData = data.object.data;
      this.showProctorStatus = proctorData.isProctored;
      this.webProctorStatus = proctorData.webProctor;
      this.videoProctorStatus = proctorData.videoProctor;
      this.pictureCaptureStatus = proctorData.pictureCapture;
      this.videoCaptureStatus = proctorData.videoCapture;
      this.audioCaptureStatus = proctorData.audioCapture;
      this.logsEnabledStatus = proctorData.logsEnabled;
    },

    async PutProctorSettingsData(action) {
      if (action == "1") {
        await this.putProctorSettings({
          testId: this.routetestId,
          IsProctored: true,
          webProctor: true,
          videoProctor: false,
          pictureCapture: true,
          videoCapture: false,
          audioCapture: false,
          logsEnabled: true,
          videoCaptureType: 2
        })
      }
      else {
        await this.putProctorSettings({
          testId: this.routetestId,
          IsProctored: false,
          webProctor: false,
          videoProctor: false,
          pictureCapture: false,
          videoCapture: false,
          audioCapture: false,
          logsEnabled: false,
          videoCaptureType: 0
        })
        window.$("#ProctorStatus").modal("hide");
        this.GetProctorSettingData();
      }
    },

    async PostLAPICDetails() {
      var sectionId = null; var questionTypeId = 0; var IsDefaultLanguage = null;

      if (this.selectedLAPICAPIName == "google" || this.selectedLAPICAPIName == 'azure' || this.selectedLAPICAPIName == 'gpt') {
        questionTypeId = this.selectedLAPICQuestTypeId;
        IsDefaultLanguage = true;
        sectionId = null;
      }
      else {
        questionTypeId = 0;
        IsDefaultLanguage = null;
        sectionId = this.selectedLAPICSecId;
      }

      var response = await this.postLanguageApiConditionDetails({
        testId: this.routetestId,
        questionTypeId: questionTypeId,
        sectionId: sectionId,
        apiName: this.selectedLAPICAPIName,
        languageName: this.selectedLAPICLanguageName,
        IsDefaultLanguage: IsDefaultLanguage
      });

      if (response.status) {
        await this.GetLanguageApiConditionData();
      }
    },

    showAutoExpiryModal() {
      window.$("#SetAutoExpiry").modal("show");
    },

    async SetAutoExpirySettings() {
      if (this.autoExpiryDay == "") {
        this.ShowWarningMessage("Enter a valid number.");
      }
      else if (this.autoExpiryDay <= 0 || this.autoExpiryDay > 30) {
        this.ShowWarningMessage("Expiry duration should be between 1 day and 30 days.");
      } else {
        var response = await this.PostAutoExipryTestSetting({
          testId: this.routetestId,
          clientId: this.testSummary.clientId,
          days: this.autoExpiryDay
        })

        if (response.status) {
          await this.TestDetails();
        }

        window.$("#SetAutoExpiry").modal("hide");
      }

    },
    blockDecimal(event) {
      const value = event.target.value;
      // Remove any non-integer characters
      event.target.value = value.replace(/\D/g, '');
      // Update the model value
      this.autoExpiryDay = event.target.value;
    },


    async PutAdvanceSettings() {
      var data = await this.putAdvanceSettings({
        testId: this.routetestId,
        preferenceName: this.advanceType,
        preferenceValue: this.advanceModalStatus
      })
      if (data) {
        await this.GetAdvanceSettings();
        window.$("#AdvanceValidation").modal("hide");
        this.advanceType = '';
        this.advanceModalStatus = false;
        this.advanceModalTitle = '';
      }
    },
    async ResetPutAdvanceSettings() {
      this.advanceType = '';
      this.advanceModalStatus = false;
      this.advanceModalTitle = '';
    },

    async GetCandidateDetailsData() {
      var data = await this.getCandidateAdditionalDetails({
        testId: this.routetestId
      });
      if (data.status) {
        this.AdvanceCandidateDetails = data.object.data;
        this.showAdditionalField = true;
      }
      else {
        this.AdvanceCandidateDetails = [];
        this.showAdditionalField = false;
      }
    },

    async GetAdvanceSettings() {
      var data = await this.getAdvanceSettings({
        testId: this.routetestId
      })
      var advanceData = data.object.data;
      this.IsBehaviouralInterviewQuestionEnabledStatus = advanceData.isBehaviouralInterviewQuestionEnabled;
      this.IsEvaluationEnabledStatus = advanceData.isEvaluationEnabled;
      this.IsManagerialReccomendationEnabledStatus = advanceData.isManagerialReccomendationEnabled;
      this.IsShowAnswerSheetStatus = advanceData.isShowAnswerSheet;

    },

    async GetReportSettingsData() {
      var data = await this.getReportSettingsDetails({
        TestId: this.routetestId
      });

      var ReportData = data.object.data;

      this.aptiShortenStatus = ReportData.aptiShorten;
      this.aptiDetailedStatus = ReportData.aptiDetailed;
      this.psyShortenStatus = ReportData.psyShorten;
      this.psyDetailedStatus = ReportData.psyDetailed;

    },
    async GetTestSectionsDetailsData() {
      var data = await this.getTestSectionsDetails({
        testId: this.routetestId // this.routetestId 
      });
      if (data.status &&  data.object.data.weightages.length > 0) {
        var details = data.object.data;
        this.secWeiActivationDate = moment(details.activationDate).format('YYYY-MM-DDTHH:mm');
        this.TestSectionsDetails = details.weightages;
        this.TestSectionsDetails.forEach(element => {
          this.secWeiSectionList = this.secWeiSectionList.filter(item=>item.secId != element.sectionId);
          if(element.sectionId == 0){
            this.secWeiSectionList = this.secWeiSectionList.filter(item=>item.secId != null);
          } 
        });
        
        this.TestSectionsDetails = this.TestSectionsDetails.map(item => {
            if(item.sectionId == 1010){
              item.sectionName = "PMaps eVA™";
            }
            else if(item.sectionId == 0){
              item.sectionName = "Overall Test";
            }
            return item;  
          });

        this.showSectionWeigtage = true;
      }
      else {
        this.TestSectionsDetails = [];
        this.showSectionWeigtage = false;
      }
    },

    async GetLanguagePreferencesData() {
      var data = await this.getLanguagePreferencesDetails({
        testId: this.routetestId
      });

      var LanguageData = data.object.data;

      this.segregatePreferences(LanguageData);
    },

    async GenerateDefaultLanguageWeightage() {
      await this.postDefaultLanguageWeightageDetails({
        testId: this.routetestId
      });

      await this.GetLanguageWeightageData();
    },

    async GenerateDefaultLanguageValues() {
      await this.postGenerateDefaultLanguageValues({
        testId: this.routetestId
      });

      await this.GetLanguageApiConditionData();
    },

    async GetLanguageWeightageData() {
      var weights = await this.getLanguageWeightageDetails({
        testId: this.routetestId
      });

      if (weights.status && weights.object.data.sections.length > 0) {
        this.model = weights.object.data.sections;
        this.showEvaWeightages = true;
      }
      else {
        this.showEvaWeightages = false;
      }
    },

    segregatePreferences(item) {
      item.forEach(preference => {
        if (preference.preferenceType === "text") {
          this.textPreferences.push(preference);
          this.defaultTextPreferences.push({ ...preference });
          this.changedPreferences.push(false);

        } else if (preference.preferenceType === "bool") {
          this.boolPreferences.push(preference);

        } else if (preference.preferenceType === "drop") {
          this.dropPreferences.push(preference);
          this.defaultDropdownPreferences.push({ ...preference });
          this.changedDropdownPreferences.push(false);
        }
      });
    },
    async GetLanguageApiConditionData() {
      var response = await this.getLanguageApiConditionDetails({
        testId: this.routetestId
      });

      if (response.status && response.object.data.length > 0) {
        this.showLanguageAPIValue = true;
        this.lAPICModel = response.object.data;
      }
      else {
        this.showLanguageAPIValue = false;
      }
    },

    markChanged(index) {
      this.changedPreferences[index] = this.textPreferences[index].preferenceValue !== this.defaultTextPreferences[index].preferenceValue;
    },
    dropChanged(index) {
      this.changedDropdownPreferences[index] =
        this.dropPreferences[index].preferenceValue !==
        this.defaultDropdownPreferences[index].preferenceValue;
    },

    async updatePreference(item) {
      // this.defaultTextPreferences[index].preferenceValue = this.textPreferences[index].preferenceValue;
      // this.changedPreferences[index] = false;
      var status;
      if (item.preferenceValue != '') {
        status = true;
      }
      else {
        status = false
      }
      var data = await this.putLanguagePreferencesDetails({
        testId: this.routetestId,
        preferenceName: item.preferenceName,
        preferenceLabel: item.preferenceLabel,
        preferenceType: item.preferenceType,
        preferenceValue: item.preferenceValue,
        isActive: status
      });

      if (data.status) {
        await this.resetEva();
        await this.GetLanguagePreferencesData();
      }
    },

    navigateToId(id) {

      const element = document.getElementById(id);
      if (element) {
        const offsetTop = element.offsetTop - 100;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    },

    resetEva() {
      this.textPreferences = [];
      this.boolPreferences = [];
      this.dropPreferences = [];
      this.defaultTextPreferences = [];
      this.defaultDropdownPreferences = [];
      this.changedPreferences = [];
      this.changedDropdownPreferences = [];
    },
    togglePreference(value) {
      if (value.preferenceValue == "FALSE") {
        this.preferenceModalTitle = "Do you want to enable " + value.preferenceLabel + " ?";
        this.preferenceModalStatus = true;
      }
      else {
        this.preferenceModalTitle = "Do you want to disable " + value.preferenceLabel + " ?";
        this.preferenceModalStatus = false;
      }
      this.currentPreferenceValue = value;
      window.$("#PreferenceValidation").modal("show");
    },
    async updatePreferenceData() {
      var data = await this.putLanguagePreferencesDetails({
        testId: this.routetestId,
        preferenceName: this.currentPreferenceValue.preferenceName,
        preferenceLabel: this.currentPreferenceValue.preferenceLabel,
        preferenceType: this.currentPreferenceValue.preferenceType,
        preferenceValue: this.preferenceModalStatus ? "TRUE" : "FALSE",//this.currentPreferenceValue.preferenceValue,
        isActive: true
      });

      if (data.status) {
        this.preferenceModalTitle = '';
        this.preferenceModalStatus = false;
        this.currentPreferenceValue = [];
        await this.resetEva();
        await this.GetLanguagePreferencesData();
        window.$("#PreferenceValidation").modal("hide");
      }
    },

    async ResetTogglePreference() {
      this.preferenceModalTitle = '';
      this.preferenceModalStatus = false;
    },
    async GetTestDurationData() {
      var data = await this.getTestDuration({
        TestId: this.routetestId
      });
      // var startTime = data.object.data.start;
      // var endTime = data.object.data.end;
      if (data.status) {
        this.TestDurationStatus = true;
        this.scheduledStartTime = data.object.data.start;
        this.scheduledEndTime = data.object.data.end;
      }

      // const offsetMatch = this.user.timezone.match(/([+-]\d{2}):?(\d{2})?/);
      // const hours = parseInt(offsetMatch[1]);
      // const minutes = offsetMatch[2] ? parseInt(offsetMatch[2]) : 0;
      // const offset = hours * 60 + minutes;

      // this.scheduledStartTime = moment.utc(startTime).utcOffset(offset).format("YYYY-MM-DDTHH:mm");
      // this.scheduledEndTime = moment.utc(endTime).utcOffset(offset).format('YYYY-MM-DDTHH:mm');

    },

    showSectionWeigtagePanel() {
      //window.$("#AddSectionWeightageModal").modal("show");
      this.showSectionWeigtage = true;
    },
    showAdditionalFieldPanel() {
      this.showAdditionalField = true;
    },

    async TestDetails() {
      var testdetail = await this.activetestdetail({
        testId: this.routetestId
      });
      this.testSummary = testdetail.object.data
      if (this.testSummary.startTime != null || this.testSummary.endTime != null) {
        this.TestDurationStatus = true;
      }
      
      this.editTestName = this.testSummary.testName;
    },

    showTab(tab){
      if(tab == 'Eva'){
        return this.testSummary.isEvaActive;
      }
      else if(tab == 'General'){
        return false;
      }
    },

    async OverallData() {
      await this.TestDetails();
      var sections = await this.activetestsection({
        testId: this.routetestId
      });
      this.testSection = sections.object.data;
      this.FillSectionList();
    },

    async GeneralData() {
      this.editTestName = this.testSummary.testName;
    },


    async UpdateTestName() {
      if (this.editTestName == '') {
        this.editTestName = this.testSummary.testName;
        this.ShowWarningMessage("Test name can't be empty");
      }
      else {
        var data = await this.putTestName({
          testId: this.routetestId,
          testName: this.editTestName
        });
        if (data.status) {
          await this.TestDetails();
        }
      }
    },

    async initCoolingPeriodDetails() {

      var duration = await this.GetCoolingPeriodDurationNew({
        clientId: this.testSummary.clientId
      });
      this.newCoolingDuration = duration.object.data;

      const settings = await this.GetCoolingPeriodSetting({
        testId: this.routetestId,
        clientId: this.testSummary.clientId
      });

      if (settings.status) {
        this.coolingPeriodSettings = settings.object.data;

        this.selectedDuration = this.coolingPeriodSettings.duration.days;
        const selectedNames = this.coolingPeriodSettings.elements.map(el => el.elementName);
        this.selectedOptions = selectedNames.filter(i=> this.coolingPeriodOptions.some(item => item.value === i)); 
      }
    },

    async PutReportSettingsDetails() {
      await this.putReportSettingsDetails({
        TestId: this.routetestId,
        aptiShorten: this.aptiShortenStatus,
        aptiDetailed: this.aptiDetailedStatus,
        psyShorten: this.psyShortenStatus,
        psyDetailed: this.psyDetailedStatus
      });
    },
    encode: function (item) {
      return window.btoa(item);
    },


    validateEndMax() {
      if (this.scheduledStartTime && this.scheduledEndTime) {
        if (new Date(this.scheduledEndTime) < new Date(this.scheduledStartTime)) {
          this.scheduleTestShow = false;
          return this.ShowWarningMessage("End Time can't be less than Start Time");
        }
        this.scheduleTestShow = true;
      }
      return false;
    },

    FillSectionList(){
      var hasEva = false;
      this.secWeiSectionList = [];
      this.testSection.forEach(element => {
        if(element.parentSecId == 1010 || element.parentSecId == 1011){
          hasEva = true;
        }
        else if(element.parentSecId != 1010 || element.parentSecId != 1011){
          this.secWeiSectionList.push({ "secId": element.secId, "sectionName":element.sectionName});
        }
      });

      if(hasEva){
        this.secWeiSectionList.push({ "secId": 1010, "sectionName":"PMaps eVA™"});  
      }

      this.secWeiSectionList.push({ "secId": null, "sectionName": "Overall Test" });
    },

    async changeTab(value) {
      await this.ResetTabs();
      switch (value) {
        case 'general':
          this.generalStatus = true;
          await this.resetEva();
          this.GeneralData();
          this.GetProctorSettingData();
          break;
        case 'overview':
          this.overviewStatus = true;
          await this.resetEva();
          break;
        case 'test-time':
          this.TestTimeStatus = true;
          await this.resetEva();
          if (this.user.accountLocation != this.user.countryCode) {
            this.getTimeZones();
          }
          this.GetTestDurationData();
          break;
        case 'eva':
          this.evaStatus = true;
          await this.resetEva();
          this.GetLanguagePreferencesData();
          this.GetLanguageWeightageData();
          this.GetLanguageApiConditionData();
          break;
        case 'advance':
          this.advanceStatus = true;
          await this.resetEva();
          this.GetAdvanceSettings();
          this.GetCandidateDetailsData();
          this.GetReportSettingsData();
          this.GetTestSectionsDetailsData();
          break;
        default:
          break;
      }
    },
    getWeightage(sectionName, parameterName) {
      const section = this.sectionNames.find(s => s.sectionName === sectionName);
      if (!section) return '';
      const param = section.languageParameters.find(p => p.parameterName === parameterName);
      return param ? param.weightageValue : 'NA';
    },

    highlightError(moduleName, parameterName, sectionName) {
      var weightageSum = 0;
      switch(moduleName){
        case "eva":
          if (sectionName == "eVA™") {
            this.sectionNames.filter(item => item.sectionName == "eVA™").forEach(item => {
              if (item.languageParameters && item.languageParameters.length > 0) {
                item.languageParameters.forEach(langParams => {
                  weightageSum += langParams.weightageValue;
                });
              }
            });
          }
          else {
            this.sectionNames.forEach(sections => {
              if (sections.sectionId > 0) {
                sections.languageParameters.forEach(langParams => {
                  if (langParams.parameterName == parameterName) {
                    weightageSum = weightageSum + langParams.weightageValue;
                  }
                });
              }
            });
          }
          break;
        case "sectionWeightage":
          this.TestSectionsDetails.forEach(item => {  
            if (item.sectionId != 0) {      
              weightageSum = Math.round((weightageSum + Number.EPSILON) * 100) / 100 + Math.round((item.weightageValue + Number.EPSILON) * 100) / 100;
            }
          });
          break;
        default:
          break;
      }
      
      if (weightageSum == 1)
        return false;
      else
        return true;
    },

    async UpdateTestDurationData() {
      var timezone;
      var status = true;
      if (this.user.accountLocation != this.user.countryCode) {
        if (!this.TimeZoneValue) {
          status = false;
          return this.ShowWarningMessage("Select Timezone Data");
        }
        timezone = this.TimeZoneValue;
      }
      else {
        timezone = this.user.timezone;
      }

      if (status) {
        await this.putTestDuration({
          testId: this.routetestId,
          start: this.scheduledStartTime,
          end: this.scheduledEndTime,
          timeZone: timezone,
          isActive: true
        });
        await this.TestDetails();
      }

    },

    ResetTabs() {
      this.generalStatus = false;
      this.overviewStatus = false;
      this.TestTimeStatus = false;
      this.evaStatus = false;
      this.advanceStatus = false;
    },
    async toggleCollapse(item) {
      if (item == "TestDurationStatus") {
        if (this.TestDurationStatus == true && (this.testSummary.startTime != null || this.testSummary.endTime != null)) {
          window.$("#DurationStatus").modal("show");
        }
        else {
          this.scheduledStartTime = "";
          this.scheduledEndTime = "";
          this.TestDurationStatus = !this.TestDurationStatus;
        }
      }
      else if (item == "showProctorStatus") {
        if (this.showProctorStatus == true) {
          window.$("#ProctorStatus").modal("show");
        }
        else {
          this.PutProctorSettingsData('1');
          this.showProctorStatus = !this.showProctorStatus;
        }
      }

    },

    async toggleWebProctor() {
      this.webProctorStatus = !this.webProctorStatus;
      if (!this.webProctorStatus) {
        this.videoProctorStatus = false;
        this.pictureCaptureStatus = false;
        this.videoCaptureStatus = false;
        this.audioCaptureStatus = false;
        this.logsEnabledStatus = false;
      }
    },
    async toggleVideoProctor() {
      this.videoProctorStatus = !this.videoProctorStatus;
    },
    async togglepictureCapture() {
      this.pictureCaptureStatus = !this.pictureCaptureStatus;
    },
    async toggleVideoCapture() {
      this.videoCaptureStatus = !this.videoCaptureStatus;
    },
    async toggleAudioCapture() {
      this.audioCaptureStatus = !this.audioCaptureStatus;
    },
    async toggleLogsEnabled() {
      this.logsEnabledStatus = !this.logsEnabledStatus;
    },
    async toggleAptiShortenStatus() {
      this.aptiShortenStatus = !this.aptiShortenStatus;
    },
    async toggleAptiDetailedStatus() {
      this.aptiDetailedStatus = !this.aptiDetailedStatus;
    },
    async togglePsyShortenStatus() {
      this.psyShortenStatus = !this.psyShortenStatus;
    },
    async togglePsyDetailedStatus() {
      this.psyDetailedStatus = !this.psyDetailedStatus;
    },
    async toggleIsBehaviouralInterviewQuestionEnabled() {
      if (!this.IsBehaviouralInterviewQuestionEnabledStatus) {
        this.advanceModalTitle = "Do you want to enable behavioural interview questions?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable behavioural interview questions?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'BehaviouralInterviewQuestionEnabled';
      window.$("#AdvanceValidation").modal("show");
    },

    async toggleIsEvaluationEnabled() {
      if (!this.IsEvaluationEnabledStatus) {
        this.advanceModalTitle = "Do you want to enable manual evaluation?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable manual evaluation?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'EvaluationEnabled';
      window.$("#AdvanceValidation").modal("show");
    },

    async toggleIsManagerialReccomendationEnabled() {
      if (!this.IsManagerialReccomendationEnabledStatus) {
        this.advanceModalTitle = "Do you want to enable managerial reccomendation?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable managerial reccomendation?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'ManagerialRecommendationEnabled';
      window.$("#AdvanceValidation").modal("show");
    },

    async toggleIsShowAnswerSheet() {
      if (!this.IsShowAnswerSheetStatus) {
        this.advanceModalTitle = "Do you want to enable show answer sheet?";
        this.advanceModalStatus = true;
      }
      else {
        this.advanceModalTitle = "Do you want to disable show answer sheet?";
        this.advanceModalStatus = false;
      }
      this.advanceType = 'ShowAnswerSheet';
      window.$("#AdvanceValidation").modal("show");
    },

    async UpdateCoolingPeriodData(status) {
      if(this.selectedDuration == ""){
        this.ShowWarningMessage("Cooling Period Duration cannot be empty");
      }
      else if(this.selectedDuration == -1 && this.newDurationDay === "") {
        this.ShowWarningMessage("Custom Duration cannot be empty");
      }
      else if(this.selectedDuration == -1 && (this.newDurationDay < 1 ||  this.newDurationDay > 365)) {
        this.ShowWarningMessage("Custom Duration cannot be less than 1 and cannot be more than 365 days");
      }
      else if(this.selectedOptions.length == 0){
        this.ShowWarningMessage("Cooling Period Mode cannot be empty");
      }
      else {
        var name;
        var days;
        var id = 0;

        if (this.selectedDuration == -1) {
          name = `${this.newDurationDay} Days`;
          days = this.newDurationDay;
        }
        else {
          const selectedItem = this.newCoolingDuration.find(item => item.days === this.selectedDuration);
          if (selectedItem) {
            name = selectedItem.name;
            days = selectedItem.days;
            id = selectedItem.id;
          }
        }

        if (this.selectedDuration == -1) {
          await this.CoolingPeriodDuration(id, name, days);
        }
        else {
          await this.CoolingPeriodSettings(id, status);
          await this.TestDetails();
          if(status == 0){
            this.selectedDuration = 0;
            this.selectedOptions=[];
          }
        }
      }
    },

    async CoolingPeriodSettings(id, status) {
      await this.CoolingPeriodSettingsAddUpdate({
        clientId: this.testSummary.clientId,
        testId: this.routetestId,
        durationId: id,
        validationType: 1,
        coolingPeriodType: 2,
        elements: this.selectedOptions,
        status: status
      });
    },

    async CoolingPeriodDuration(id, name, days) {

      var data = await this.CoolingPeriodDurationAddUpdate({
        clientId: this.testSummary.clientId,
        testId: this.routetestId,
        days: days,
        name: name,
        durationId: id
      });

      if (data.status) {
        var newDurationId = data.object.data.id;
        await this.CoolingPeriodSettings(newDurationId);
      }
    },


    async patchDuration() {

      await this.patchTestDuration({
        testId: this.routetestId,
        status: false
      });
      this.TestDurationStatus = !this.TestDurationStatus;
      await this.TestDetails();
      window.$("#DurationStatus").modal("hide");

    },

    async UpdateTestDisclaimer() {
      await this.PatchTestDisclaimer({
        testId: this.routetestId,
        Disclaimer: this.disclaimer
      });
    },

    async resetData() {
      await this.GetTestDurationData();
    },
  },
};
</script>

<style scoped>
.accordion-button::after {
  display: none !important;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;
  background-color: #fff !important;
}

.toggle-button {
  width: 52px;
  min-width: 52px;
  height: 28px;
  background-color: #ccc;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.secondary-button1 {
  border: 2px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  font-weight: 600;
  border-radius: 21px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 13px;
}

.col-sm-12 .btn-primary {
  margin-left: 5%;
}

.font-15 {
  font-size: 15px;
}

.toggle-button::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-button.active {
  background-color: #7b68ee;
}

.toggle-button.active::before {
  transform: translateX(100%);
}

.toggle-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.toggle-button.mandatorybutton:disabled {
  background-color: #c9c0ff;
  cursor: not-allowed;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
}

.table-header {
  text-align: center;
  margin-bottom: 10px;
}

.table-title {
  font-size: 18px;
  font-weight: bold;
}

.select-container {
  position: relative;
  width: 100%;
}

.custom-select {
  width: 100%;
  border-radius: 5px;
  padding-left: 5%;
  height: 100%;
  min-height: 50px;
  appearance: none;
  padding-right: 30px;
}

.select-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.QuillEditor-kj {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  /* margin-top: 45px; */
  border: 2px solid #d1d5db;
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.highlight-red {
  background: red;
}
</style>